import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import { Button, IconButton, Pagination, Typography } from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { ModalEdit } from "../../components/Modal/ModalEdit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import translate from "../../i18n/messages/translate";
import { I18n } from "../../i18n";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import _ from "lodash";
import FirstPreventivo from "./firstPreventivo";
import CartellaPreventivi from "./cartellaPreventivi";
import CustomizedSnackbars from "../../components/Message/Snackars";
import ClientiForm from "../../components/Formik/clientiForm";
import PaginationButton from "../../components/Paginazione/PaginationButton";
import { useParams } from "react-router";
import { importBaseActions } from "../../store/reducers/base";
import { usePaginationStyles } from "../../core/MuiStyles";

const NewPreventivo = () => {
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();

  //const history = useHistory();
  const navigate = useNavigate();  
  const classes = usePaginationStyles();
  
  const [paginationModel, setPaginationModel] = React.useState({
    page: 1,
    pageSize: 25,
  });

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const handleClick = () => setOpen(true);
  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  // const fetcher = (url) =>
  //   axios
  //     .post(url, { id: count.id, page: page }, axiosConfig)
  //     .then((res) => res.data.data);
  // const { data, error } = useSWR(
  //   [`${process.env.REACT_APP_API_URL}/quote`, count.success, page],
  //   fetcher
  // );

  useEffect(() => {
    load();
  }, [paginationModel]);

  useEffect(() => {
    setData(null);
    setPaginationModel({...paginationModel, page: 1});
  }, [state.customer]);

  const load = async () => {
    try
    {
      if (state.user.userType != "Retail" && !state.customer) {
        navigate("/clienti");
        return;
      }

      let res = await axios.post(`${process.env.REACT_APP_API_URL}/quote`, { id: state.customer ? state.customer.id : state.user.id, page: paginationModel.page, pageSize: paginationModel.pageSize }, axiosConfig);
      if (res.data.success !== 1) throw res.data.message;

      setData(res.data.data);
    }
    catch (e)
    {
      console.error(e);
      setError(e);
    }
  }

  const getBackUrl = () => {
    if (!state.customer.isServiceCustomer) return "/clienti";

    let destination = "";
    switch (state.user.userType) {
      case "SuperAdmin":
      case "Admin":
          destination = "/area-managers";
          break;
      case "AreaManager":
          destination = "/agenti";
          break;

      case "Agente":
          destination = "/clienti";
          break;

      case "Retail":
          destination = "/configurazioni";
          break;
    }
    return destination;
  }

  if (error) {
    return <div>failed to load</div>;
  }

  return (
    <I18n locale={state.locale}>
      <LayoutPage>
        <TopBar />
        <div className={"d-flex justify-content-between p-3"}>
          <div style={{lineHeight: "1"}}>
            <h2 className="h2 fw-bold">           
              {translate("Preventivi")}           
            </h2>
            {state.customer && <span className="badge bg-dark text-white">{state.customer.lastName} {state.customer.firstName} {state.customer.isServiceCustomer && location.state && location.state.parentUser && <span>di {location.state.parentUser?.name} {location.state.parentUser?.surname}</span>}</span>}
            {state.user.userType == "Retail" && <span className="badge bg-dark text-white">{state.user.lastName} {state.user.firstName}</span>}
          </div>
          <div>
            {state.customer && <Link to={getBackUrl()} className="btn btn-secondary me-2">
              <ArrowBackIcon />
            </Link>}
            <button className="btn btn-primary" type="button" onClick={() => {
                dispatch(baseActions.clearStorage());
                navigate("/nuova-configurazione");
              }}>
              <AddIcon />
              {translate("NUOVO_PREVENTIVO")}
            </button>
          </div>
        </div>
        <ModalEdit
          title={"Modifica cliente"}
          open={openEdit}
          handleClose={handleCloseEdit}>
          <ClientiForm handleClose={handleCloseEdit} user={state.customer ? state.customer.id : state.user.id} />
        </ModalEdit>

        <CustomizedSnackbars open={open} setOpen={setOpen} />
        {!data ? (
          <Loading />
        ) : _.isEmpty(data.data) === true ? (
          <FirstPreventivo />
        ) : (
          <CartellaPreventivi data={data.data} />
        )}
        {data && data.total > 0 && (
          // <PaginationButton
          //   page={page}
          //   setPage={setPage}
          //   lastPage={data.last_page}
          // />
          <Pagination
            className={classes.root}
            style={{flexDirection: "row-reverse"}}
            color="primary"
            count={Math.ceil(data.total / paginationModel.pageSize)}
            page={paginationModel.page}
            onChange={(event, value) => setPaginationModel({...paginationModel, page: value})}
          />
        )}
      </LayoutPage>
    </I18n>
  );
};

export default NewPreventivo;
