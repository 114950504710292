import React from "react";
import { Routes, Route } from "react-router-dom";
import { AnonymousConfigurationsPage } from "./anonymous_configurations_page";

export const ConfigurationsRoutes = () => {

    return (
        <Routes>
            <Route path="/anonymous" element={<AnonymousConfigurationsPage />} />
        </Routes>
    );
}