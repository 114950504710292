import { useAspettoApi } from "../api/AspettoApi"

export function useAspettoService() {
    const aspettoApi = useAspettoApi();

    return {
        async saveInstallationAccessories(token, data) {
            return aspettoApi.saveInstallationAccessories(token, data);
        }
    }
}