import axios from "axios";

export function useAspettoApi() {
    return {
        async saveInstallationAccessories(token, data) {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/save-installation-accessories`, data, { 
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            return response.data;
        }
    }
}