import { useEffect, useState } from "react";
import Card from "./Card";
import update from "immutability-helper";
import { useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { importBaseActions } from "../../store/reducers/base";

const style = {
  maxHeight: "80%",
  width: 500,
};

const Container = ({ img, idPos, idAcc, numberItem }) => {
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  
  const [item, setItem] = useState(state.posizione);
  const location = useLocation();

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = item[dragIndex];
    setItem(
      update(item, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  const onClickCustom = (id, location) => {
    const newArr = item.map((obj) => {
      if (obj.id === id) {
        return {
          ...obj,
          id_pos: idPos,
          id_acc: idAcc,
          applied: !obj.applied,
          type: location === "/finestrature" ? "finestrature" : "ventilazione",
          img: img,
          qty: numberItem,
        };
      }
      return obj;
    });
    setItem(newArr);
    dispatch(baseActions.setPosizione(newArr));
  };

  useEffect(() => {
    //dispatch(baseActions.setPosizione(item));
  }, [item]);

  return (
    <div style={style}>
      {item &&
        item.map((card, i) => (
          <Card
            onClickCustom={() => onClickCustom(card.id, location.pathname)}
            key={card.id}
            index={i}
            id={card.id}
            img={card.img}
            type={card.type}
            applied={card.applied}
            moveCard={moveCard}
          />
        ))}
    </div>
  );
};

export default Container;
