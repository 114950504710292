import axios from "axios";
import { CollectionRequestResult, NullableNumber, SingleRequestResult } from "../core/common_types"
import { Customer } from "../models/customer"
import { getAxiosConfig } from "../core/functions";
import { User } from "../models/user";

export const useCustomersApi = () => {
    return {
        async search(currentUser: User, searchText: string, page: number, pageSize: number): Promise<CollectionRequestResult<Customer>> {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/customers/search?count=${pageSize}&page=${page}`, {
                searchText
            }, getAxiosConfig(currentUser));

            if (response.status != 200) {
                throw response.statusText;
            }

            return response.data;
        },
        async findServiceCustomerId(currentUser: User, parentId: number): Promise<SingleRequestResult<NullableNumber>> {
            let response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/find-service-customer-id?parentId=${parentId}`, getAxiosConfig(currentUser));

            if (response.status != 200) {
                throw response.statusText;
            }

            return response.data;
        }
    }
}