//import { DateTime } from "luxon";

export const ITEMS_PER_PAGE = 25;

// export interface Page {
//     number: number;
//     label: string;
//     isCurrentPage: boolean;
// }

// export interface Pagination {
//     previousPageNumber: number | null;
//     pages: Page[];
//     nextPageNumber: number | null;
// }

export function buildPagination(currentPage/*: number*/, totalItemsCount/*: number*/, itemsPerPage/*: number*/)//: Pagination
{
    let totalPages = Math.ceil(totalItemsCount / itemsPerPage)
    if (totalPages <= 1) return null;

    let pageNeighbours/*: number*/ = 2;

    let startPage = currentPage < pageNeighbours + 1 ? 1 : currentPage - pageNeighbours;
    const endPage = totalPages < (pageNeighbours * 2) + startPage ? totalPages : (pageNeighbours * 2) + startPage;
    const diff = (startPage - endPage) + (pageNeighbours * 2);
    
    startPage -= (startPage - diff > 0 ? diff : 0);

    let actualPageRange = new Array((endPage - startPage) + 1).fill(0).map((_, i) => i + startPage);
    if (actualPageRange[0] !== 1) actualPageRange = [1, ...actualPageRange];
    if (actualPageRange[1] !== 2) actualPageRange = [1, -1, ...actualPageRange.slice(1)];
    if (actualPageRange[actualPageRange.length - 1] !== totalPages) {
        actualPageRange = [...actualPageRange, totalPages];
    }
    if (actualPageRange[actualPageRange.length - 2] !== totalPages - 1) {
        actualPageRange = [...actualPageRange.slice(0, actualPageRange.length - 1), -1, totalPages];
    }

    let pagination/*: Pagination*/ = {
        previousPageNumber: currentPage > 0 ? currentPage - 1 : null,
        pages: actualPageRange.map(p => 
        {
            return {
                number: p,
                label: p == -1 ? "..." : p.toString(),
                isCurrentPage: p == currentPage
            }/* as Page*/;
        }),
        nextPageNumber: currentPage < (totalPages - 1) ? totalPages - 1 : null
    };

    return pagination;
}

// export function formatDateTime(dateIso/*: string*/) {
//     let dt = DateTime.fromSQL(dateIso+"Z");
//     return dt.toFormat("dd-LL-yyyy HH:mm");
// }

export function getProductName(idPorta) {
    switch (idPorta) {
        case 3:
            return "Overbas";
        case 2:
            return "Overlap";
    }
    return "";
}