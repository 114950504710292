import * as React from "react";
import TextField from "@mui/material/TextField";

export const InputCustom = ({
  name,
  value,
  helperText,
  error,
  label,
  placeholder,
  onChange,
  type,
  multiline,
  rows,
  hidden,
  InputProps,
  sx,
  disabled,
  onFocus,
  size,
  onBlur,
}) => {
  return (
    <TextField
      margin="normal"
      fullWidth
      id={name}
      label={label}
      placeholder={placeholder}
      name={name}
      type={type}
      disabled={disabled}
      value={value}
      hidden={hidden}
      onChange={onChange}
      error={error}
      multiline={multiline}
      rows={rows}
      sx={sx}
      helperText={helperText}
      InputProps={InputProps}
      onFocus={onFocus}
      size={size}
      onBlur={onBlur}
    />
  );
};
