import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#df5b23",
    },
    secondary: {
      main: "#4a4137",
    },
  },
  greenCustom: "#19C35D",
  typography: {
    fontFamily: "'Titillium Web', sans-serif",
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
});
