import { User } from "../models/user";

export function getCurrentUser(storeBase: any) {
    return storeBase.user as User;
}

export function getAxiosConfig(currentUser: User) {
    return {
        headers: {
            Authorization: "Bearer " + currentUser.token,
        },
    };
}