import { LOCALES } from "../locales";

export default {
  [LOCALES.ITALIAN]: {
    IMPOSTAZIONI: "IMPOSTAZIONI",
    ASSISTENZA: "ASSISTENZA",
    I_tuoi_agenti_rivenditori: "I tuoi agenti / rivenditori",
    agents: "Agenti",
    areaManagers: "Area Managers",
    NUOVO_AGENTE: "NUOVO AGENTE",
    Nome_agente: "Nome agente",
    Data_ultima_modifica: "Data ultima modifica",
    Clienti: "Clienti",
    Scontistica_generica: "Scontistica generica",
    MODIFICA: "MODIFICA",
    RESET_PASSWORD: "RESET PASSWORD",
    ELIMINA: "ELIMINA",
    CLIENTI_ARCHIVIATI: "CLIENTI ARCHIVIATI",
    GESTIONE_SCONTI: "GESTIONE SCONTI",
    MODIFICA_AGENTE: "MODIFICA AGENTE",
    Ordina_per: "Ordina per",
    Preventivi: "Configurazioni",
    ARCHIVIA_CLIENTE: "ARCHIVIA CLIENTE",
    MODIFICA_CLIENTE: "MODIFICA CLIENTE",
    NUOVO_PREVENTIVO: "NUOVA CONFIGURAZIONE",
    Crea_il_tuo_primo_preventivo: "Crea la tua prima configurazione",
  },
};
