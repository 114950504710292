import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  Button,
  Checkbox,
  Switch,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "redux";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputCustom } from "../../components/Input/InputCustom";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import Loading from "../../components/Loading/loading";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import FormControlLabel from "@mui/material/FormControlLabel";
import { importBaseActions } from "../../store/reducers/base";
import { getProductName } from "../../Utility";

const validationSchema = yup.object({
  qty: yup
    .number()
    .typeError("Inserire un numero intero")
    .required("Quantità richiesta obbligatoria"),
  altezza: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Altezza obbligatoria"),
  larghezza: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Larghezza obbligatoria"),
  architrave: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Architrave obbligatoria"),
  spallettaSX: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Spalletta Sinistra obbligatoria"),
  spallettaDX: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Spalletta Destra obbligatoria"),
  // SMuroSuperiore: yup
  //   .number()
  //   .typeError("Inserire un numero valido"),
  // SMuroSinistro: yup
  //   .number()
  //   .typeError("Inserire un numero valido"),
  // SMuroDestro: yup
  //   .number()
  //   .typeError("Inserire un numero valido"),
  PGarage: yup
    .number()
    .typeError("Inserire un numero valido")
    .required("Profondità Garage obbligatoria"),
});

const Dimensioni = () => {
  const state = useSelector(state => state.base);
  const dispatch = useDispatch();
  const baseActions = importBaseActions();
  const [data, setData] = useState({
    state: "loading", 
    steps: [],
    currentStep: null,
    dimensioni: null
  });

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const { cache } = useSWRConfig();
  const [errorVincolo, setErrorVincolo] = useState(null);

  const location = useLocation();
  const [value, setValue] = useState(0);
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  const [checked, setChecked] = useState(state.selectedD);
  const [switchDebordante, setSwitchDebordante] = useState(state.isDebordante);
  const [switchPorticina, setSwitchPorticina] = useState(state.isPorticina);
  const [switchPorticinaAffiancata, setSwitchPorticinaAffiancata] = useState(state.isPorticinaAffiancata);

  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  const getData = () => {
    formik.setFieldValue("altezza", state.altezza);
    formik.setFieldValue("larghezza", state.larghezza);
    formik.setFieldValue("architrave", state.architrave);
    formik.setFieldValue("spallettaSX", state.spallettaSinistra);
    formik.setFieldValue("spallettaDX", state.spallettaDestra);
    formik.setFieldValue("SMuroSuperiore", state.SMuroSuperiore);
    formik.setFieldValue("SMuroSinistro", state.SMuroSinistro);
    formik.setFieldValue("SMuroDestro", state.SMuroDestro);
    formik.setFieldValue("PGarage", state.PGarage);
  };

  useEffect(() => {
    //return getData();
    load();
  }, []);

  const load = async () => {     
    let steps = [];
    let currentStep = null;
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: state.idPorta, quote_id: state.quoteId }, axiosConfig);      
      console.log(res);
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = findCategoryStep(steps);
        console.log(currentStep);
      }      
    } catch (e) {
      
    }

    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/get-dimensioni`, { id: state.customer ? state.customer.id : state.user.id, quote: state.quoteId }, axiosConfig);

      let dimensioni = res.data.data;
      if (dimensioni) {        
        formik.setFieldValue("qty", dimensioni.qty);
        formik.setFieldValue("altezza", dimensioni.height);
        formik.setFieldValue("larghezza", dimensioni.width);
        formik.setFieldValue("architrave", dimensioni.architrave);
        formik.setFieldValue("spallettaSX", dimensioni.distance_ss);
        formik.setFieldValue("spallettaDX", dimensioni.distance_ds);
        formik.setFieldValue("SMuroSuperiore", dimensioni.upper_wall_thickness);
        formik.setFieldValue("SMuroSinistro", dimensioni.left_wall_thickness);
        formik.setFieldValue("SMuroDestro", dimensioni.right_wall_thickness);
        formik.setFieldValue("PGarage", dimensioni.garage_depth);
        formik.setFieldValue("locale_con_accesso", dimensioni.locale_con_accesso == 1);
        //formik.setFieldValue("with_door", dimensioni.with_door == 1);
        formik.setFieldValue("with_side_door", dimensioni.with_side_door == 1);
        dispatch(baseActions.setSelectedD(dimensioni.locale_con_accesso == 1));
        //dispatch(baseActions.setIsPorticina(dimensioni.with_door == 1));
        dispatch(baseActions.setIsPorticinaAffiancata(dimensioni.with_side_door == 1));
      }
        
      setData({
        state: "idle",
        steps: steps, 
        currentStep: currentStep,
        dimensioni
      });
    } catch (e) {
      console.error(e);
      setData({state: "error", error: e});
    }
  }

  const findCategoryStep = (categoriesSteps) => {    
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const navigateToNextStep = () => {
    let nextStep = null;
    let stepsIds = data.steps.map(s => s.position);
    let maxStepId = Math.max(...stepsIds);

    for (let stepId = data.currentStep.position + 1; stepId <= maxStepId; stepId++) {
      let step = data.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (state.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      nextStep = step;
      if (nextStep) break;
    }    
    if (!nextStep) return;

    navigate(`/${nextStep.slug}`, { 
      id: state.idPorta,
    });
  }

  const setQty = (value) => {
    formik.setFieldValue("qty", value);
  }

  // useEffect(() => {
  //   cache.clear();
  //   return handleChange();
  // }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      qty: "0",
      altezza: "0",
      larghezza: "0",
      architrave: "0",
      spallettaSX: "0",
      spallettaDX: "0",
      SMuroSuperiore: "0",
      SMuroSinistro: "0",
      SMuroDestro: "0",
      PGarage: "0",
      locale_con_accesso: false,
      is_debordante: false,
      //with_door: false,
      with_side_door: false
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      values.architrave = state.architrave;
      values.SMuroSuperiore = state.SMuroSuperiore;
      values.PGarage = state.PGarage;
      values.SMuroSinistro = state.SMuroSinistro;
      values.spallettaSX = state.spallettaSinistra;
      values.spallettaDX = state.spallettaDestra;
      values.SMuroDestro = state.SMuroDestro;
      values.larghezza = state.larghezza;
      values.altezza = state.altezza;
      const valid = validationSchema.isValidSync(values);
      if (!valid) {
        setErrorVincolo("Attenzione, assicurati di aver inserito tutte le misure necessarie.");
        return;
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/dimensioni`,
          {
            id: state.customer ? state.customer.id : state.user.id,
            qty: values.qty,
            category_id: state.idPorta,
            quote: state.quoteId,
            altezza: state.altezza,
            larghezza: state.larghezza,
            architrave: state.architrave,
            spallettaSX: state.spallettaSinistra,
            spallettaDX: state.spallettaDestra,
            SMuroSuperiore: state.SMuroSuperiore,
            SMuroSinistro: state.SMuroSinistro,
            SMuroDestro: state.SMuroDestro,
            PGarage: state.PGarage,
            locale_con_accesso: state.selectedD,
            is_debordante: state.isDebordante,
            //with_door: state.isPorticina,
            with_side_door: state.isPorticinaAffiancata,
          },
          axiosConfig
        )
        .then((res) => {          
          if (res.data.error === 1) {
            setErrorVincolo(res.data.message);
          } else {            
            dispatch(baseActions.setDimensione(true));
            navigateToNextStep();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  if ("error" == data.state) return <div>failed to load</div>;
  if ("loading" == data.state) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      <div className={"flex justify-between items-center my-6 w-full"}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant="h4" component="div" style={{ marginLeft: "1rem" }}>
            Nuova configurazione {getProductName(state.idPorta)} (#{state.quoteId})
          </Typography>
        </Grid>
        <div>
          <Button
            style={{ marginRight: ".5rem" }}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            ANNULLA
          </Button>
      
          <Button
            style={{ marginRight: ".5rem" }}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            SALVA E ESCI
          </Button>
        </div>
      </div>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          <TabsCustom
            value={value}
            setValue={setValue}
            count={state}
            id={state.idPorta}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />
        </Grid>
        {data.dimensioni &&
        <GridPreventivi
          header={null
            // <div className="grid grid-cols-2 xl:grid-cols-4 mb-4">
            //   <div className="xl:col-start-2 col-span-2 text-center">
            //     <Typography fontWeight={700} variant="h4" mt={5}>
            //       Dimensioni
            //     </Typography>
            //   </div>
            // </div>
          }
          dataSend={{
            quote_id: state.quoteId,
            category_id: state.idPorta,
            slug: location.pathname.substring(1),
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dimensioni={data.dimensioni && data.dimensioni}>
          <form onSubmit={formik.handleSubmit}>
            {/*state.idPorta === 3 && (
              <div className={"grid grid-cols-1 xl:grid-cols-2 gap-x-4 justify-items-start"}>
                <div className={"flex flex-col"}>
                  <Typography
                    color={"primary"}
                    sx={{ lineHeight: 0, marginTop: 3, marginBottom: 1 }}>
                    Debordante*
                  </Typography>
                  <Switch
                    checked={switchDebordante}
                    defaultChecked={
                      state.isDebordante === true
                        ? true
                        : formik.values.is_debordante !== 0
                    }
                    onChange={(e) => {
                      (e.target.checked);
                      dispatch(baseActions.setIsDebordante(e.target.checked));
                      formik.setFieldValusetSwitchDebordantee("is_debordante", e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div>
            )*/}
            <div className={"grid grid-cols-3 xl:grid-cols-4 gap-x-4 justify-items-center mt-3"}>
              <div className="col-start-2 xl:col-start-2 xl:col-span-2 pl-6 pr-6 xl:pl-0 xl:pr-0 w-full">
                <Typography
                  color={"primary"}
                  sx={{ lineHeight: 0, marginTop: 2 }}>
                  Quantità di richiesta*
                </Typography>
                <InputCustom
                  name={"qty"}
                  placeholder={"mm"}
                  formik={formik.values}
                  error={formik.touched.qty && Boolean(formik.errors.qty)}
                  value={formik.values.qty}
                  helperText={formik.touched.qty && formik.errors.qty}
                  onChange={e => setQty(e.target.value)}
                />
              </div>
            </div>
            <div className={"grid grid-cols-3 xl:grid-cols-4 gap-x-4 justify-items-center"}>
              <div className="col-start-2  xl:col-start-2 xl:col-span-2 w-full pl-6 pr-6 xl:pl-0 xl:pr-0 col-span-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"accesso"}
                      formik={formik.values}
                      defaultChecked={state.selectedD}
                      value={state.selectedD}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        dispatch(baseActions.setSelectedD(e.target.checked));
                        formik.setFieldValue("locale_con_accesso", e.target.checked);
                      }}
                    />
                  }
                  label={"Locale garage con altro accesso"}
                />
              </div>
            </div>
            <div className={"grid grid-cols-3 xl:grid-cols-4 gap-x-4 justify-items-center"}>
              <div className="col-start-2  xl:col-start-2 xl:col-span-2 w-full pl-6 pr-6 xl:pl-0 xl:pr-0 col-span-2" style={{display: "flex", flexDirection: "column"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"accesso"}
                      formik={formik.values}
                      defaultChecked={state.isPorticinaAffiancata}
                      value={state.isPorticinaAffiancata} 
                      onChange={(e) => {
                        setSwitchPorticinaAffiancata(e.target.checked);
                        dispatch(baseActions.setIsPorticinaAffiancata(e.target.checked));
                        formik.setFieldValue("with_side_door", e.target.checked);
                      }}
                    />
                  }
                  label={"Aggiungi portina pedonale affiancata"}
                />
                {/*state.idPorta == 3 && <FormControlLabel
                  control={
                    <Checkbox
                      name={"accesso"}
                      formik={formik.values}
                      defaultChecked={state.isPorticina}
                      value={state.isPorticina}
                      onChange={(e) => {
                        setSwitchPorticina(e.target.checked);
                        dispatch(baseActions.setIsPorticina(e.target.checked));
                        formik.setFieldValue("with_door", e.target.checked);
                      }}
                    />
                  }
                  label={"Aggiungi portina pedonale integrata"}
                />*/}
                {/*state.idPorta == 3 && <FormControlLabel
                  control={
                    <Checkbox
                      name={"accesso"}
                      formik={formik.values}
                      value={state.isDebordante}
                      defaultChecked={state.isDebordante}
                      onChange={(e) => {
                        setSwitchDebordante(e.target.checked);
                        dispatch(baseActions.setIsDebordante(e.target.checked));
                        formik.setFieldValue("is_debordante", e.target.checked);
                      }}
                    />
                  }
                  label={"Debordante"}
                />*/}
                {/* <Typography
                  color={"primary"}
                  sx={{ lineHeight: 0, marginTop: 3, marginBottom: 1 }}>
                  Porticina*
                </Typography>
                <Switch
                  checked={switchPorticina}
                  defaultChecked={
                    state.isPorticina === true
                      ? true
                      : formik.values.with_door !== 0
                  }
                  onChange={(e) => {
                    setSwitchPorticina(e.target.checked);
                    dispatch(baseActions.setIsPorticina(e.target.checked));
                    formik.setFieldValue("with_door", e.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                /> */}
              </div>
            </div>
            {errorVincolo && (
              <div className="grid grid-cols-4 xl:grid-cols-4 mt-6">
                <div className="col-start-2 col-span-2">
                  <Alert severity="error">{errorVincolo}</Alert>
                </div>
              </div>
            )}

            <div className="grid grid-cols-4 xl:grid-cols-4 gap-x-4 mb-[75px] mt-6">
              <div className="col-start-2">
                <Button
                  fullWidth
                  color={"secondary"}
                  variant={"contained"}
                  sx={{color: "white", fontWeight: "bold", py: 1 }}
                  onClick={() => navigate("/configurazioni")}>
                  INDIETRO
                </Button>
              </div>
              <div>
                <Button
                  // onClick={handleSubmit}
                  fullWidth
                  sx={{ color: "white", fontWeight: "bold", py: 1 }}
                  variant={"contained"}
                  type={"submit"}>
                  AVANTI
                </Button>
              </div>  
            </div>
          </form>
        </GridPreventivi>}
      </Grid>
    </LayoutPage>
  );
};
const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Dimensioni;
