import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";

const CheckboxCustom = ({
  name,
  value,
  label,
  axiosConfig,
  idAcc,
  quote,
  selected,
  disabled,
  finestre,
}) => {
  const [checked, setChecked] = useState(false);
  const [checkedDefault, setCheckedDefault] = useState(true);
  const location = useLocation();

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
    setCheckedDefault(event.target.checked);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/save-accessories`,
        {
          qty: event.target.checked === false ? 0 : 1,
          id: idAcc,
          quote: quote,
          slug: location.pathname.substring(1),
          is_active: 0,
          accessorio: 1,
        },
        axiosConfig
      )
      .then((res) => console.log(res.data))
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          name={name}
          checked={
            selected === 0
              ? checkedDefault
              : selected === idAcc
              ? checkedDefault
              : checked
          }
          onChange={handleChangeChecked}
          value={value}
        />
      }
      label={label}
    />
  );
};

export default CheckboxCustom;
