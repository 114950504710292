import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import logo from "../../assets/img/LogoMenu.png";
import avatar from "../../assets/img/Avatar.png";
import italy from "../../assets/img/Italy.png";
import UK from "../../assets/img/UnitedKingdom.png";
import { Avatar, IconButton, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import ExitIcon from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";
import ArchiveIcon from "@mui/icons-material/Archive";
import HomeIcon from "@mui/icons-material/Home";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { I18n, LOCALES } from "../../i18n";
import translate from "../../i18n/messages/translate";
import { useState } from "react";
import { ModalEdit } from "../Modal/ModalEdit";
import CustomizedSnackbars from "../Message/Snackars";
import ProfileForm from "../Formik/profileForm";
import { importBaseActions } from "../../store/reducers/base";

const TopBar = () => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const currentUser = store.user;

  //const history = useHistory();
  const navigate = useNavigate();
  // LIST MOBILE MENU
  const [open, setOpen] = useState(false);
  const [isShowUserMenu, setIsShowUserMenu] = useState(false);
  const handleClick = () => setOpen(true);

  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = () => setOpenEdit(true);

  const location = useLocation();

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250, height: "100%", display: "flex", flexDirection: "column" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <div className="bg-primary text-white">
        <div className="d-flex align-items-center p-3">
          <Avatar src={store.user.logo && store.user.logo} sx={{ mr: 1, width: "2rem", height: "2rem" }} className="bg-dark text-white">
            {!store.user.logo && getInitials()}
          </Avatar>
          <span className="fw-bold">
            {store.user.firstName} {store.user.lastName}
          </span>
        </div>
      </div>

      <div style={{flex: "1 1 auto"}}>
        <List>
            <ListItem onClick={() => goToHome()}>
              <div className="d-flex align-items-center">              
                <HomeIcon className="text-primary me-2" style={{width: "1.1rem", height: "1.1rem"}} />
                <span className="text-secondary">Home</span>
              </div>
            </ListItem>
            <Divider />
            <ListItem onClick={() => showAnonymousConfigurations()}>
              <div className="d-flex align-items-center">              
                <ArchiveIcon className="text-primary me-2" style={{width: "1.1rem", height: "1.1rem"}} />
                <span className="text-secondary">Configurazioni anonime</span>
              </div>
            </ListItem>
            <Divider />
            <ListItem onClick={() => navigate("/customers/search")}>
              <div className="d-flex align-items-center">              
                <SearchIcon className="text-primary me-2" style={{width: "1.1rem", height: "1.1rem"}} />
                <span className="text-secondary">Ricerca Clienti</span>                  
              </div>
            </ListItem>
            <Divider />
        </List> 
      </div>

      <div className="bg-secondary text-white p-2">
        <button type="button" className="btn btn-primary btn-sm" onClick={() => logout()}>
          <LogoutIcon style={{width: "1.1rem", height: "1.1rem"}} />
          <span className="ms-2">Disconnetti</span>
        </button>
      </div>
    </Box>
  );

  const getInitials = () => {
    let firstName = store.user.firstName;
    let lastName = store.user.lastName;
    let initials = "";
    if (firstName) initials += firstName.substr(0, 1);
    if (lastName) initials += lastName.substr(0, 1);
    return initials;
  }

  const goToHome = () => {
    let destination = "";
    switch (currentUser.userType) {
      case "SuperAdmin":
      case "Admin":
          destination = "/area-managers";
          break;
      case "AreaManager":
          destination = "/agenti";
          break;

      case "Agente":
          destination = "/clienti";
          break;

      case "Retail":
          destination = "/configurazioni";
          break;
    }
    navigate(destination);
  }

  const logout = () => {
    dispatch(baseActions.logoutUser());
    navigate("/login");
  }

  const showAnonymousConfigurations = () => {
    dispatch(baseActions.setCustomer({id: store.user.serviceCustomerId, lastName: "Configurazioni", firstName: "Anonime", isServiceCustomer: true}));
    navigate("/configurazioni");
  }

  // if (!data) return <Loading />;
  // if (error) return <ErrorCustom />;
  return (
    //  TOP BAR DESKTOP
    <>
      <I18n locale={store.locale}>
        <TopbarResponsive sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar className="justify-content-between">
              <div>
                <img src={logo} alt={"img"} />
              </div>

              {store.user.userType != "Retail" && (                  
                <div className="d-flex">
                  <StyledLink
                    onClick={() => goToHome()}
                    style={{ fontWeight: "bold" }}
                    color="inherit">
                    <HomeIcon style={{width: "1.1rem", height: "1.1rem"}} />
                    HOME
                  </StyledLink>

                  <Typography
                    sx={{
                      mt: 2,
                      mb: 3
                    }}
                    fontWeight={100}
                    variant="h6"
                    component="div">
                    |
                  </Typography>

                  <StyledLink 
                    style={{ fontWeight: "bold" }}
                    color="inherit"
                    onClick={() => showAnonymousConfigurations()}>
                    <ArchiveIcon style={{width: "1.1rem", height: "1.1rem"}} />
                    <span className="ms-1">CONFIGURAZIONI ANONIME</span>
                  </StyledLink>

                  <Typography
                    sx={{
                      mt: 2,
                      mb: 3
                    }}
                    fontWeight={100}
                    variant="h6"
                    component="div">
                    |
                  </Typography>

                  <StyledLink
                    onClick={() => navigate("/customers/search")}
                    style={{ fontWeight: "bold" }}
                    color="inherit">
                    <SearchIcon style={{width: "1.1rem", height: "1.1rem"}} />
                    Ricerca Clienti
                  </StyledLink>
                </div>
              )}

              <div className="d-flex align-items-center">
                <div className="btn-group">
                  <button type="button" className={"btn btn-secondary dropdown-toggle " + isShowUserMenu ? "show" : ""} onClick={() => setIsShowUserMenu(!isShowUserMenu)}>
                    <div className="d-flex align-items-center">
                      <Avatar src={store.user.logo && store.user.logo} sx={{ mr: 1, width: "2.5rem", height: "2.5rem" }} className="bg-dark text-white">
                        {!store.user.logo && getInitials()}
                      </Avatar>
                      <span className="fw-bold text-end" style={{lineHeight: "1.1"}}>
                        {store.user.firstName} {store.user.lastName}<br />
                        <small className="fw-normal">{store.user.code}</small>
                      </span>
                    </div>
                  </button>
                  {isShowUserMenu && <ul className={"dropdown-menu dropdown-menu-end dropdown-menu-user show"} style={{ position: "absolute", top: "100%", right: "0", marginTop: ".5rem" }}>
                    {!store.user.requireChangePassword && <li>
                      <Link to="/modifica-password" className="dropdown-item">
                        <LockIcon />
                        <span className="ms-2">Modifica password</span>
                      </Link>
                    </li>}
                    <li>
                      <a className="dropdown-item" onClick={() => logout()}>
                        <LogoutIcon />
                        <span className="ms-2">Disconnetti</span>
                      </a>
                    </li>
                  </ul>}
                </div>
                <Typography
                  sx={{
                    mt: 2,
                    mb: 3,
                    mr: 2,
                    ml: 2
                  }}
                  fontWeight={100}
                  variant="h6"
                  component="div">
                  |
                </Typography>
                <img
                  onClick={() => dispatch(baseActions.setLocale(LOCALES.ITALIAN))}
                  style={{ cursor: "pointer" }}
                  src={italy}
                  alt={"img"}
                />
              </div>
            </Toolbar>
          </AppBar>
        </TopbarResponsive>

        {/*// TOP BAR MOBILE*/}
        <TopbarResponsiveMobile sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Toolbar
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                    justifyItems: "flex-start",
                    cursor: "pointer",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon sx={{ color: "white" }} />
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <img src={logo} alt={"img"} />
                  </Box>
                </Toolbar>
                <Drawer
                  transitionDuration={200}
                  anchor={"left"}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}>
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </AppBar>
        </TopbarResponsiveMobile>
      </I18n>
      {/*  MODAL AGENTE */}
      <ModalEdit
        title={"Impostazioni"}
        open={openEdit}
        handleClose={handleCloseEdit}>
        <ProfileForm handleClose={handleCloseEdit} />
      </ModalEdit>
      {open && <CustomizedSnackbars open={open} setOpen={setOpen} />}
    </>
  );
};

export default TopBar;

const StyledLink = styled(Button)`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: solid underline white 2px;
    text-underline-offset: 4px;
  }
`;

const TopbarResponsive = styled(Box)`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const TopbarResponsiveMobile = styled(Box)`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;
