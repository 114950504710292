import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import { Circle } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogCustom = ({
  title,
  content,
  open,
  handleClose,
  handleCancel,
  fullScreen,
  loadingModal,
}) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      // aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", fontSize: 24 }}>
        {/* <DialogContentText>{content}</DialogContentText> */}
        {content}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }} disableSpacing={true}>
        {loadingModal ? (
          <CircularProgress />
        ) : (
          <>
            {handleCancel && <Button
              color={"secondary"}
              variant={"contained"}
              onClick={handleCancel}
              style={{marginRight: ".5rem"}}>
              ANNULLA
            </Button>}

            <Button variant={"contained"} onClick={handleClose}>
              OK
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
