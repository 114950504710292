import React from "react";
import { Routes, Route } from "react-router-dom";
import { SearchPage } from "./search_page";

export const CustomersRoutes = () => {

    return (
        <Routes>
            <Route path="/search" element={<SearchPage />} />
        </Routes>
    );
}