import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import DialogScroll from "../../components/Dialog/DialogScroll";
import axios from "axios";
import useSWR from "swr";
import DialogAccessories from "../../components/Dialog/dialogAccessories";
import Loading from "../../components/Loading/loading";
import AddIcon from "@mui/icons-material/Add";
import box from "../../assets/img/box.PNG";
import CancelIcon from "@mui/icons-material/Cancel";
import { importBaseActions } from "../../store/reducers/base";
import { OptionsTypeEnum } from "../../core/OptionsTypeEnum";
import { useCallback } from "react";
import { getProductName } from "../../Utility";

const Accessori = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [optionId, setOptionId] = useState();
  const [openColor, setOpenColor] = useState(false);
  const [nameColor, setNameColor] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [color1, setColor1] = useState();
  const [color2, setColor2] = useState();
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };

  // const fetcher = (url) =>
  //   axios.post(url, { id: count.idPorta, quote_id: count.quoteId }, axiosConfig).then((res) => res.data.data);
  // const { data, error } = useSWR([`${process.env.REACT_APP_API_URL}/categories-steps`], fetcher);

  const [value, setValue] = useState();
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  const navigateToNextStep = () => {
    let currentStep = data.find(cs => `/${cs.slug}` === location.pathname);
    
    let nextStep = null;
    let stepsIds = data.map(s => s.position);
    let maxStepId = Math.max(...stepsIds);

    for (let stepId = currentStep.position + 1; stepId <= maxStepId; stepId++) {
      let step = data.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      nextStep = step;
      if (nextStep) break;
    }    
    if (!nextStep) return;

    navigate(`/${nextStep.slug}`, { 
      id: count.idPorta,
    });
  }

  const navigateToPreviuosStep = () => {
    let currentStep = data.find(cs => `/${cs.slug}` === location.pathname);

    let previousStep = null;
    let stepsIds = data.map(s => s.position);
    let minStepId = Math.min(...stepsIds);

    for (let stepId = currentStep.position - 1; stepId >= minStepId; stepId--) {
      let step = data.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      previousStep = step;
      if (previousStep) break;
    }    
    if (!previousStep) return;

    navigate(`/${previousStep.slug}`, { 
      id: count.idPorta,
    });
  }

  const load = useCallback(async () => {
    try {
      let steps = [];
      let currentStep = null;
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: count.idPorta, quote_id: count.quoteId }, axiosConfig);      
      console.log(res);
      if (res.data && res.data.data) {
        steps = res.data.data;
        currentStep = res.data.data.find(cs => `/${cs.slug}` === location.pathname);
        setValue(currentStep.id);
      }   
      
      setData(steps);
    } catch (e) {
      setError(e);
    }
  });

  useEffect(() => load(), []);

  const fetcher3 = (url) =>
    axios
      .post(url, { quote: count.quoteId }, axiosConfig)
      .then((res) => res.data.data);
  const { data: accessories, error: errorAccessories } = useSWR(
    [`${process.env.REACT_APP_API_URL}/save-accessories`, open, count.success, reload],
    fetcher3
  );
  const fetcher4 = (url) =>
    axios
      .post(
        url,
        {
          id: count.idPorta,
          quote: count.quoteId,
          step: location.pathname.substring(1),
        },
        axiosConfig
      )
      .then((res) => res.data.data);
  const { data: Acc, error: errorAcc } = useSWR(
    [`${process.env.REACT_APP_API_URL}/accessories`, open],
    fetcher4
  );

  const fetcher5 = (url) =>
    axios
      .post(url, { category: 2, option_id: 15, parent_id: 9 }, axiosConfig)
      .then((res) => res.data.data);
  const { data: colors, error: errorColors } = useSWR(
    [`${process.env.REACT_APP_API_URL}/colors`],
    fetcher5
  );

  if (error || errorAccessories || errorColors || errorAcc) {
    return <div>failed to load</div>;
  }

  if (!data) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      <div className={"flex justify-between items-center my-6 w-full"}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant="h4" component="div" style={{ marginLeft: "1rem" }}>
            Nuova configurazione {getProductName(count.idPorta)} (#{count.quoteId})
          </Typography>
        </Grid>
        
        <div>
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            ANNULLA
          </Button>
        
          <Button
            style={{marginRight: ".5rem"}}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            SALVA E ESCI
          </Button>
        </div>
      </div>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {data && <TabsCustom
            value={value}
            setValue={setValue}
            id={count.idPorta}
            count={count}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>

        {!accessories || !colors || !Acc ? (
          <Loading />
        ) : (
          <GridPreventivi
            header={null
              // <div className="grid grid-cols-2 xl:grid-cols-4 mb-4 xl:mt-0">
              //   <div className="col-span-4 text-center">
              //     <Typography variant={"h4"} fontWeight={700} mt={5}>
              //       Accessori
              //     </Typography>
              //   </div>
              // </div>
            }
            dataSend={{
              quote_id: count.quoteId,
              category_id: count.idPorta,
            }}
            axiosConfig={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
            dipendenze={open}>
            <div className={"grid grid-cols-4 xl:mt-6 mt-10"}>
              <div className="col-start-2 col-span-2">
                <Button
                  fullWidth
                  startIcon={<AddIcon />}
                  variant={"contained"}
                  size={"xl"}
                  sx={{ fontWeight: 700, fontSize: 15, marginBottom: 3 }}
                  onClick={() => setOpen(true)}>
                  SCEGLI GLI ACCESSORI
                </Button>
                {accessories.filter(res => res.type === null || res.adding_accessories_automatic == 1).map((res, index) => {
                  console.log(res);
                  return (
                    <div
                      key={res.id}
                      className={"card mt-4"}>
                      <div className="car-body p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <span style={{fontSize: ".9rem"}}>{res.name}</span>

                          {/* <div className={"flex flex-col justify-center"}>
                            <img
                              style={{
                                width: 90,
                                height: 70,
                                padding: 2,
                                marginLeft: 6,
                              }}
                              src={res.img ? res.img : box}
                              alt={"img"}
                            />
                          </div> */}
                        
                          <IconButton
                            onClick={() => {
                              axios
                                .post(
                                  `${process.env.REACT_APP_API_URL}/save-accessories`,
                                  {
                                    id: res.id,
                                    qty: 0,
                                    quote: count.quoteId,
                                    slug: location.pathname.substring(1),
                                  },
                                  axiosConfig
                                )
                                .then(
                                  (res) =>
                                    res.data.success === 1 && setReload(!reload)
                                )
                                .catch(function (error) {
                                  console.log(error);
                                });
                            }}
                          >
                            <CancelIcon color={"primary"} />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={"grid grid-cols-4 gap-4 mt-6"}>
              <div className={"col-start-2"}>
                <Button
                  fullWidth
                  color={"secondary"}
                  variant={"contained"}
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    py: 1,
                  }}
                  onClick={() => navigateToPreviuosStep()}>
                  INDIETRO
                </Button>
              </div>
                
              <div>
                <Button
                  fullWidth
                  sx={{ color: "white", fontWeight: "bold", py: 1 }}
                  variant={"contained"}
                  onClick={() => {
                    dispatch(baseActions.setAccessori(true));
                    navigateToNextStep();
                  }}>
                  AVANTI
                </Button>
              </div>
            </div>
          </GridPreventivi>
        )}
      </Grid>
      <DialogAccessories
        open={open}
        reload={reload}
        setOpen={setOpen}
        scroll={scroll}
        setScroll={setScroll}
        axiosConfig={axiosConfig}
      />
      <DialogScroll
        open={openColor}
        setOpen={setOpenColor}
        scroll={scroll}
        setScroll={setScroll}
        data={colors}
        setNameColor={setNameColor}
        optionId={optionId}
        axios_config={axiosConfig}
        color1={color1}
        fullScreen={true}
        color2={color2}
        endpoint={"save-accessories"}
        setColor1={setColor1}
        setColor2={setColor2}
        quote_id={count.quoteId}
      />
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default Accessori;
