import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as React from "react";
import { LayoutPage } from "../Layout/layoutPage";
import axios from "axios";
import useSWR from "swr";
import Loading from "../Loading/loading";
import { InputCustom } from "../Input/InputCustom";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { url } from "../../assets/conf/env.json";
import imgDimensioni from "../../assets/img/dimensioniBuona.jpg";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { importBaseActions } from "../../store/reducers/base";
import { useRef } from "react";
import { useCallback } from "react";
import { ImageRender } from "./ImageRender";

const GridPreventivi = ({
  header,
  children,
  dataSend,
  url,
  axiosConfig,
  dipendenze,
  dimensioni,
  porticina,
  imgRendering,
}) => {
  const state = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();

  const imgContainerSize = useRef({w: 0, h: 0});
  const [inputPositions, setInputPositions] = useState({
    inputHT1: {t: 0, l: 0},
    inputST1: {t: 0, l: 0},
    inputLS1: {t: 0, l: 0},
    inputLD1: {t: 0, l: 0},
    inputPG1: {t: 0, l: 0},
    inputSS1: {t: 0, l: 0},
    inputSD1: {t: 0, l: 0},
    inputL: {t: 0, l: 0},
    inputH: {t: 0, l: 0},
  });
  
  // const fetcher = (url) =>
  //   axios.post(url, dataSend, axiosConfig).then((res) => res.data);
  // const { data, error } = useSWR([url, dipendenze], fetcher);

  useEffect(() => {
    load();
  }, []);
  
  const load = async () => {
    try {
      let res = await axios.post(url, dataSend, axiosConfig);//.then((res) => res.data);

      dimensioni && dispatch(baseActions.setAltezza(dimensioni.height));
      dimensioni && dispatch(baseActions.setLarghezza(dimensioni.width));
      dimensioni && dispatch(baseActions.setArchitrave(dimensioni.architrave));
      dimensioni && dispatch(baseActions.setSpallettaSinistra(dimensioni.distance_ss));
      dimensioni && dispatch(baseActions.setSpallettaDestra(dimensioni.distance_ds));
      dimensioni && dispatch(baseActions.setSMuroSuperiore(dimensioni.upper_wall_thickness));
      dimensioni && dispatch(baseActions.setSMuroSinistro(dimensioni.left_wall_thickness));
      dimensioni && dispatch(baseActions.setSMuroDestro(dimensioni.right_wall_thickness));
      dimensioni && dispatch(baseActions.setPGarage(dimensioni.garage_depth));
      porticina && dispatch(baseActions.setAltezzaPortina(porticina.heigth));
      porticina && dispatch(baseActions.setLarghezzaPortina(porticina.width));

      setData(res.data);
    } catch (e) {
      setError(e);
    }    
  }

  React.useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);

    return () => window.removeEventListener("resize", resizeWindow);
  }, [data]);

  const resizeWindow = useCallback(() => {
    let imgRilievoDimensioni = document.querySelector(".map_container img");
    if (!imgRilievoDimensioni) return;

    let w = imgRilievoDimensioni.offsetWidth;
    let h = imgRilievoDimensioni.offsetHeight;

    setInputPositions({
      inputHT1: {l: (1330 * w / 3508), t: (715 * h / 2480)},
      inputST1: {l: (1425 * w / 3508), t: (900 * h / 2480)},
      inputLS1: {l: (650 * w / 3508), t: (1330 * h / 2480)},
      inputLD1: {l: (2780 * w / 3508), t: (1300 * h / 2480)},
      inputPG1: {l: (100 * w / 3508), t: (1920 * h / 2480)},
      inputSS1: {l: (825 * w / 3508), t: (1020 * h / 2480)},
      inputSD1: {l: (2620 * w / 3508), t: (1020 * h / 2480)},
      inputL: {l: (1440 * w / 3508), t: (1320 * h / 2480)},
      inputH: {l: (1580 * w / 3508), t: (1580 * h / 2480)},
    });

  }, []);

  if (error) {
    console.log("gridPreventivi");
    return <div>failed to load: {error.message}</div>;
  }
  if (!data) return null;

  return (
    <LayoutPage>
      <div className="grid grid-cols-1 xl:grid-cols-2 w-full">
        {location.pathname === "/dimensioni" ? (
          <div>
            <div className="map_container">
              <img src={imgDimensioni} alt={"img"} onLoad={() => resizeWindow()} />
            
              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputHT1.t + "px", 
                left: inputPositions.inputHT1.l + "px"
              }}>
                <input 
                  type="number" 
                  name="architrave"
                  value={state.architrave ?? ""}
                  placeholder="HT1"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setArchitrave(e.target.value))}
                />
              </div>
            
              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputST1.t + "px", 
                left: inputPositions.inputST1.l + "px"
              }}>
                <input 
                  type="number" 
                  name="SMuroSuperiore"
                  value={state.SMuroSuperiore ?? ""}
                  placeholder="ST1"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setSMuroSuperiore(e.target.value))}
                />
              </div>

              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputPG1.t + "px", 
                left: inputPositions.inputPG1.l + "px"
              }}>
                <input 
                  type="number" 
                  name="PGarage"
                  value={state.PGarage ?? ""}
                  placeholder="PG"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setPGarage(e.target.value))}
                />
              </div>
              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputSS1.t + "px", 
                left: inputPositions.inputSS1.l + "px"
              }}>
                <input 
                  type="number" 
                  name="SMuroSinistro"
                  value={state.SMuroSinistro ?? ""}
                  placeholder="SS1"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setSMuroSinistro(e.target.value))}
                />
              </div>
              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputLS1.t + "px", 
                left: inputPositions.inputLS1.l + "px"
              }}>
                <input 
                  type="number" 
                  name="spallettaSinistra"
                  value={state.spallettaSinistra ?? ""}
                  placeholder="LS1"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setSpallettaSinistra(e.target.value))}
                />
              </div>
              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputLD1.t + "px", 
                left: inputPositions.inputLD1.l + "px"
              }}>
                <input 
                  type="number" 
                  name="spallettaDestra"
                  value={state.spallettaDestra ?? ""}
                  placeholder="LD1"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setSpallettaDestra(e.target.value))}
                />
              </div>

              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputSD1.t + "px", 
                left: inputPositions.inputSD1.l + "px"
              }}>
                <input 
                  type="number" 
                  name="SMuroDestro"
                  value={state.SMuroDestro ?? ""}
                  placeholder="SD1"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setSMuroDestro(e.target.value))}
                />
              </div>

              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputL.t + "px", 
                left: inputPositions.inputL.l + "px"
              }}>
                <input 
                  type="number" 
                  name="larghezza"
                  value={state.larghezza ?? ""}
                  placeholder="L"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setLarghezza(e.target.value))}
                />
              </div>

              <div className={"map-marker w-20"} style={{ 
                top: inputPositions.inputH.t + "px", 
                left: inputPositions.inputH.l + "px"
              }}>
                <input 
                  type="number" 
                  name="altezza"
                  value={state.altezza ?? ""}
                  placeholder="H"
                  min={0} 
                  step={0} 
                  className="ps-1 pe-1 border-solid border-2 rounded border-delta-overlap w-full" 
                  onChange={e => dispatch(baseActions.setAltezza(e.target.value))}
                />
              </div>
            </div>
            <div            
              style={{
                backgroundColor: "#504F4F",
                color: "#dc3d22",
              }}>
              <div className={`grid grid-cols-3 gap-4 text-sm p-4`}>
                {/*state.H && state.L && (
                  <>
                    <div>
                      <div>Dimensioni esterne telaio:</div>
                    </div>
                    <div className={" inline-block "}>
                      Larghezza:{" "}
                      <div className={"text-white inline-block "}>
                        {state.L} mm
                      </div>
                    </div>
                    <div className={"inline-block "}>
                      Altezza:{" "}
                      <div className={"text-white inline-block "}>
                        {state.H} mm
                      </div>
                    </div>
                  </>
                )*/}
                <div className={"inline-block"}>
                  Architrave:{" "}
                  <div className={"text-white inline-block"}>
                    {state.architrave} mm
                  </div>
                </div>
                <div className={"inline-block"}>
                  Larghezza:{" "}
                  <div className={"text-white inline-block"}>
                    {state.larghezza} mm
                  </div>
                </div>
                <div className={"inline-block"}>
                  Altezza:{" "}
                  <div className={"text-white inline-block"}>
                    {state.altezza} mm
                  </div>
                </div>

                <div className={"inline-block"}>
                  Spalletta destra:{" "}
                  <div className={"text-white inline-block"}>
                    {state.spallettaDestra} mm
                  </div>
                </div>
                <div className={"inline-block"}>
                  Spalletta sinistra:{" "}
                  <div className={"text-white inline-block"}>
                    {state.spallettaSinistra} mm
                  </div>
                </div>
                {/*<div className={"inline-block"}>
                  Spessore Muro Superiore:{" "}
                  <div className={"text-white inline-block"}>
                    {state.SMuroSuperiore} mm
                  </div>
                </div>
                <div className={"inline-block"}>
                  Spessore Muro Destro:{" "}
                  <div className={"text-white inline-block"}>
                    {state.SMuroDestro} mm
                  </div>
                </div>
                <div className={"inline-block"}>
                  Spessore Muro Sinistro:{" "}
                  <div className={"text-white inline-block"}>
                    {state.SMuroSinistro} mm
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        ) : location.pathname === "/portinaPedonaleAffiancata" ? (
          <div className={"relative"}>
            <img
              src={"https://pro.doorgate.pt/imgs/ps_lateral.png"}
              alt={"img"}
              style={{margin: "0 auto"}}
            />

            {/* <div className={" absolute left-36 top-12 zIndex-20"}>
              <Typography
                color={"primary"}
                sx={{ lineHeight: 0, marginTop: 2 }}>
                Altezza (mm)*
              </Typography>
              <InputCustom
                sx={{ width: 150, backgroundColor: "white", borderRadius: 2 }}
                name={"altezza"}
                value={state.altezzaPortina}
                placeholder={"Altezza"}
                onChange={(e) =>
                  dispatch(baseActions.setAltezzaPortina(e.target.value))
                }
              />
            </div>
            <div
              className={" absolute right-80  zIndex-20"}
              style={{ top: "30rem" }}>
              <Typography
                color={"primary"}
                sx={{ lineHeight: 0, marginTop: 2 }}>
                Larghezza (mm)*
              </Typography>
              <InputCustom
                sx={{ width: 150, backgroundColor: "white", borderRadius: 2 }}
                name={"larghezza"}
                value={state.larghezzaPortina}
                placeholder={"Larghezza"}
                onChange={(e) =>
                  dispatch(baseActions.setLarghezzaPortina(e.target.value))
                }
              />
            </div> */}
          </div>
        ) : (
          <div>
            <ImageRender />            
            {location.pathname !== "/dimensioni" && (
              <div            
                style={{
                  backgroundColor: "#504F4F",
                  color: "#dc3d22",
                }}>
                <div
                  className={`grid grid-cols-3 gap-4 text-sm p-4`}>
                  {/*state.H && state.L && (
                    <>
                      <div>
                        <div>Dimensioni esterne telaio:</div>
                      </div>
                      <div className={" inline-block "}>
                        Larghezza:{" "}
                        <div className={"text-white inline-block "}>
                          {state.L} mm
                        </div>
                      </div>
                      <div className={"inline-block "}>
                        Altezza:{" "}
                        <div className={"text-white inline-block "}>
                          {state.H} mm
                        </div>
                      </div>
                    </>
                  )*/}
                  <div className={"inline-block"}>
                    Architrave:{" "}
                    <div className={"text-white inline-block"}>
                      {state.architrave} mm
                    </div>
                  </div>
                  <div className={"inline-block"}>
                    Larghezza:{" "}
                    <div className={"text-white inline-block"}>
                      {state.larghezza} mm
                    </div>
                  </div>
                  <div className={"inline-block"}>
                    Altezza:{" "}
                    <div className={"text-white inline-block"}>
                      {state.altezza} mm
                    </div>
                  </div>

                  <div className={"inline-block"}>
                    Spalletta destra:{" "}
                    <div className={"text-white inline-block"}>
                      {state.spallettaDestra} mm
                    </div>
                  </div>
                  <div className={"inline-block"}>
                    Spalletta sinistra:{" "}
                    <div className={"text-white inline-block"}>
                      {state.spallettaSinistra} mm
                    </div>
                  </div>
                  {/*<div className={"inline-block"}>
                    Spessore Muro Superiore:{" "}
                    <div className={"text-white inline-block"}>
                      {state.SMuroSuperiore} mm
                    </div>
                  </div>
                  <div className={"inline-block"}>
                    Spessore Muro Destro:{" "}
                    <div className={"text-white inline-block"}>
                      {state.SMuroDestro} mm
                    </div>
                  </div>
                  <div className={"inline-block"}>
                    Spessore Muro Sinistro:{" "}
                    <div className={"text-white inline-block"}>
                      {state.SMuroSinistro} mm
                    </div>
                  </div>*/}
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          {header}
          {children}
        </div>
      </div>      
    </LayoutPage>
  );
};

export default GridPreventivi;
