import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";

const PaginationButton = ({ page, setPage, lastPage }) => {
  return (
    <Box sx={{ marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
      <Button
        disabled={page === 1 && true}
        onClick={() => setPage(page - 1)}
        sx={{ marginRight: 5, borderRadius: 30 }}
        variant={"contained"}
      >
        <ArrowBackIcon />
      </Button>
      <Button
        disabled={page === lastPage && true}
        onClick={() => setPage(page + 1)}
        sx={{ marginRight: 2, borderRadius: 30 }}
        variant={"contained"}
      >
        <ArrowForwardIcon />
      </Button>
    </Box>
  );
};

export default PaginationButton;
