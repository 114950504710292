import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import { InputCustom } from "../../components/Input/InputCustom";
import * as yup from "yup";
import CheckboxCustom from "../../components/Checkbox/CheckboxCustom";
import box from "../../assets/img/box.PNG";
import Box from "@mui/material/Box";
import { importBaseActions } from "../../store/reducers/base";
import { getProductName } from "../../Utility";
import posizione_sinistra from "../../assets/img/portinaIntegrata/sinistra.png";
import posizione_destra from "../../assets/img/portinaIntegrata/destra.png";
import posizione_centrata from "../../assets/img/portinaIntegrata/centrata.png";
import apertura_destra from "../../assets/img/portinaIntegrata/apartura-destra.png";
import apertura_sinistra from "../../assets/img/portinaIntegrata/apertura-sinistra.png";

const validationSchema = yup.object({
  altezza: yup.string().required("Altezza obbligatoria"),
  larghezza: yup.string().required("Larghezza obbligatoria"),
  installazione: yup.string().required("Installazione obbligatoria"),
  apertura: yup.string().required("Apertura obbligatoria"),
  verso: yup.string().required("Verso obbligatorio"),
});

const PortinaPedonaleIntegrata = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: "loading",
    steps: [],
    currentStep: null,
    posizione: null,
    apertura: null,
    coloreTelaioInterno: "",
    coloreManigliaEsterna: "",
    coloreManigliaInterna: "",
    error: null
  });

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [errorVincolo, setErrorVincolo] = useState(null);

  const [open, setOpen] = useState(false);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };

  const [value, setValue] = useState();
  const handleChange = () => {
    // data &&
    //   data.map((el) =>
    //     `/${el.slug}` === location.pathname ? setValue(el.id) : null
    //   );
  };

  useEffect(async () => {
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories-steps`, { id: count.idPorta, quote_id: count.quoteId }, axiosConfig);
      if (res.data && res.data.data) {
        let steps = res.data.data;
        let currentStep = findCategoryStep(steps);
        setState({
          ...state,
          steps,
          currentStep,
          status: "idle"
        });
      }
    } catch (e) {

    }

    try {
      let res = axios.post(`${process.env.REACT_APP_API_URL}/get-id-accessories`, {
        quote: count.quoteId,
        slug: location.pathname.substring(1),
        type: "radio",
      }, axiosConfig);
      
      //res.data.data.accessories_id

      res = axios.post(`${process.env.REACT_APP_API_URL}/get-id-accessories`, {
        quote: count.quoteId,
        slug: location.pathname.substring(1),
        type: "checkbox",
      }, axiosConfig);
    } catch (e) {

    }
  }, []);

  const fetcher2 = (url) =>
    axios
      .post(
        url,
        {
          id: count.idPorta,
          quote: count.quoteId,
          step: location.pathname.substring(1),
        },
        axiosConfig
      )
      .then((res) => res.data.data);
  const { data: accessories, error: errorAccessories } = useSWR(
    [`${process.env.REACT_APP_API_URL}/accessories`, location.pathname],
    fetcher2
  );

  const navigateToPreviuosStep = () => {
    let previousStep = null;
    let stepsIds = state.steps.map(s => s.position);
    let minStepId = Math.min(...stepsIds);

    for (let stepId = state.currentStep.position - 1; stepId >= minStepId; stepId--) {
      let step = state.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      previousStep = step;
      if (previousStep) break;
    }
    if (!previousStep) return;

    navigate(`/${previousStep.slug}`, {
      id: state.idPorta,
    });
  }

  const findCategoryStep = (categoriesSteps) => {
    return categoriesSteps.find(cs => `/${cs.slug}` === location.pathname);
  };

  const submit = async () => {
    if (!state.posizione) {
      setErrorVincolo("Selezionare la posizione della porticina");
      return;
    }

    if (!state.apertura) {
      setErrorVincolo("Selezionare l'apertura della porticina");
      return;
    }

    let data = {
      quote_id: count.quoteId,
      posizione: state.posizione,
      apertura: state.apertura,
      ralCustomACCP60: state.coloreManigliaEsterna,
      ralCustomACCP61: state.coloreManigliaInterna,
      ralCustomACCP51: state.coloreTelaioInterno
    }

    let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-porticina-integrata`, data, axiosConfig);
    
    if (res.data.error === 1) {
      setErrorVincolo(res.data.message);
    } else if (res.data.success === 1) {
      dispatch(baseActions.setRadioI(true));
      dispatch(baseActions.setPortinaPedonale(true));
      navigateToNextStep();
    }
  }
  
  const navigateToNextStep = () => {
    let nextStep = null;
    let stepsIds = state.steps.map(s => s.position);
    let maxStepId = Math.max(...stepsIds);

    for (let stepId = state.currentStep.position + 1; stepId <= maxStepId; stepId++) {
      let step = state.steps.find(s => s.position == stepId);
      if (!step) continue;
      if (count.fullglass && (step.position == 6 || step.position == 7 || step.position == 8)) {
        continue;
      }
      nextStep = step;
      if (nextStep) break;
    }    
    if (!nextStep) return;

    navigate(`/${nextStep.slug}`, { 
      id: count.idPorta,
    });
  }

  // if (error || errorAccessories) {
  //   console.log("AT - portinaPedonaleIntegrata");
  //   return <div>failed to load</div>;
  // }
  // if (!data || !accessories) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      <div className={"flex justify-between items-center my-6 w-full"}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant="h4" component="div" style={{ marginLeft: "1rem" }}>
            Nuova configurazione {getProductName(count.idPorta)} (#{count.quoteId})
          </Typography>
        </Grid>

        <div>
          <Button
            style={{ marginRight: ".5rem" }}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large"
          >
            ANNULLA
          </Button>

          <Button
            style={{ marginRight: ".5rem" }}
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large">
            SALVA E ESCI
          </Button>
        </div>
      </div>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ justifyContent: "center" }}>
          {state.currentStep && <TabsCustom
            value={state.currentStep.id}
            setValue={setValue}
            id={count.idPorta}
            count={count}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />}
        </Grid>
        <GridPreventivi
          header={null
            // <div className="grid grid-cols-2 xl:grid-cols-4 mb-4 xl:mt-0">
            //   <div className="col-span-4 text-center">
            //     <Typography variant={"h4"} fontWeight={700} mt={5}>
            //       Portina Pedonale Integrata
            //     </Typography>
            //   </div>
            // </div>
          }
          dataSend={{
            quote_id: count.quoteId,
            category_id: count.idPorta,
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dipendenze={open}>
          {/*count.isPorticina && (
            <div className={"grid grid-cols-5 xl:mt-6 mt-10"}>
              <div className="col-start-2 col-span-3">
                <Typography
                  color={"primary"}
                  sx={{ lineHeight: 0, marginTop: 6, marginBottom: 2 }}
                >
                  Tipologia
                </Typography>
                <FormControl fullWidth>
                  <Select onChange={handleChangeIntegrata} value={integrata}>
                    <MenuItem value={0} sx={{ display: "none" }}>
                      Inserisci Tipologia Portina Integrata
                    </MenuItem>
                    {accessories.map((res) => {
                      if (res.type === "radio") {
                        return (
                          res.type === "radio" && (
                            <MenuItem key={res.id} value={res.id}>
                              {res.name}
                            </MenuItem>
                          )
                        );
                      }
                    })}
                  </Select>
                </FormControl>
                <FormGroup sx={{ mt: 6 }}>
                  {accessories.map((res) => {
                    return (
                      <div key={res.id}>
                        {res.type === "checkbox" && (
                          <>
                            <CheckboxCustom
                              selected={res.selected}
                              value={res.sku}
                              name={res.sku}
                              label={res.name + "("+res.sku+")"}
                              axiosConfig={axiosConfig}
                              idAcc={res.id}
                              quote={count.quoteId}
                            />
                            {res.sku.startsWith("ACCP51") && (
                              <>
                                <Typography
                                  color={"primary"}
                                  sx={{
                                    lineHeight: 0,
                                    marginTop: 2,
                                  }}
                                >
                                  Colore (SKU: {res.sku})
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: 2,
                                  }}
                                >
                                  <InputCustom
                                    name={"colore_telaio_interno"}
                                    value={coloreTelaioInterno}
                                    placeholder={"Inserisci il codice (es. 9010)"}
                                    onChange={(e) => {
                                      setColoreTelaioInterno(e.target.value);
                                      axios
                                        .post(
                                          `${process.env.REACT_APP_API_URL}/get-accessories-notstandard`,
                                          {
                                            color: e.target.value,
                                            base: "ACCP51",
                                            quote_id: count.quoteId,
                                            slug: location.pathname.substring(1),
                                          },
                                          axiosConfig
                                        )
                                        .then((res) => setImg(res.data.data.img))
                                        .catch(function (error) {
                                          console.log(error);
                                        });
                                    }}
                                  />
                                  <img
                                    style={{
                                      width: 90,
                                      height: 70,
                                      marginTop: 12,
                                      padding: 2,
                                      marginLeft: 6,
                                    }}
                                    src={img ? img : box}
                                    alt={"imgLeft"}
                                  />
                                </Box>
                              </>
                            )}
                            {res.sku.startsWith("ACCP60") && (
                              <>
                                <Typography
                                  color={"primary"}
                                  sx={{
                                    lineHeight: 0,
                                    marginTop: 2,
                                  }}
                                >
                                  Colore (SKU: {res.sku})
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: 2,
                                  }}
                                >
                                  <InputCustom
                                    name={"colore_maniglia_esterna"}
                                    value={coloreEsternoManiglia}
                                    placeholder={"Inserisci il codice (es. 9010)"}
                                    onChange={(e) => {
                                      setColoreEsternoManiglia(e.target.value);
                                      axios
                                        .post(
                                          `${process.env.REACT_APP_API_URL}/get-accessories-notstandard`,
                                          {
                                            color: e.target.value,
                                            base: "ACCP60",
                                            quote_id: count.quoteId,
                                            slug: location.pathname.substring(1),
                                          },
                                          axiosConfig
                                        )
                                        .then((res) => setImg2(res.data.data.img))
                                        .catch(function (error) {
                                          console.log(error);
                                        });
                                    }}
                                  />
                                  <img
                                    style={{
                                      width: 90,
                                      height: 70,
                                      marginTop: 12,
                                      padding: 2,
                                      marginLeft: 6,
                                    }}
                                    src={img2 ? img2 : box}
                                    alt={"imgLeft"}
                                  />
                                </Box>
                              </>
                            )}
                            {res.sku.startsWith("ACCP61") && (
                              <>
                                <Typography
                                  color={"primary"}
                                  sx={{
                                    lineHeight: 0,
                                    marginTop: 2,
                                  }}
                                >
                                  Colore (SKU: {res.sku})
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: 2,
                                  }}
                                >
                                  <InputCustom
                                    name={"colore_interno_maniglia"}
                                    value={coloreInternoManiglia}
                                    placeholder={"Inserisci il codice (es. 9010)"}
                                    onChange={(e) => {
                                      setColoreInternoManiglia(e.target.value);
                                      axios
                                        .post(
                                          `${process.env.REACT_APP_API_URL}/get-accessories-notstandard`,
                                          {
                                            color: e.target.value,
                                            base: "ACCP61",
                                            quote_id: count.quoteId,
                                            slug: location.pathname.substring(1),
                                          },
                                          axiosConfig
                                        )
                                        .then((res) => setImg3(res.data.data.img))
                                        .catch(function (error) {
                                          console.log(error);
                                        });
                                    }}
                                  />
                                  <img
                                    style={{
                                      width: 90,
                                      height: 70,
                                      marginTop: 12,
                                      padding: 2,
                                      marginLeft: 6,
                                    }}
                                    src={img3 ? img3 : box}
                                    alt={"imgLeft"}
                                  />
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </FormGroup>
                {errorVincolo && <Alert severity="error">{errorVincolo}</Alert>}

              <div className={"mt-12 grid grid-cols-2 pb-56 h-10 gap-4"}>
                <Button
                  fullWidth
                  color={"secondary"}
                  variant={"contained"}
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    py: 1,
                  }}
                  onClick={() => navigateToPreviuosStep()}>
                  INDIETRO
                </Button>
                <Button
                  fullWidth
                  sx={{ color: "white", fontWeight: "bold", py: 1 }}
                  variant={"contained"}
                  onClick={() => {
                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/save-accessories`,
                        {
                          qty: 1,
                          id: integrata,
                          slug: location.pathname.substring(1),
                          quote: count.quoteId,
                          is_active: count.isPorticina ? 1 : 0,
                        },
                        axiosConfig
                      )
                      .then((res) => {
                        if (res.data.data.error) {
                          setErrorVincolo(res.data.data.error);
                        } else {
                          dispatch(baseActions.setRadioI(integrata));
                          dispatch(baseActions.setPortinaPedonale(true));
                          navigate("/accessori");
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                >
                  AVANTI
                </Button>
              </div>
            </div>
            </div>)*/}
          {count.isPorticina && (
            <div className="row p-0">
              <div className="col-6 offset-3 col-xl-8 offset-xl-2 p-0">
                <div className="mt-3">
                  <h5 className="h5 text-primary mb-1">Posizione</h5>
                  <div className="row">
                    <div className="col-4">
                      <div className={"card card-posizione p-1 " + (state.posizione == "left" ? "active" : "")} onClick={() => setState({...state, posizione: "left"})}>
                        <img src={posizione_sinistra} />
                        <span className="d-block text-center">A sinistra</span>
                      </div>
                    </div>

                    <div className="col-4">
                    <div className={"card card-posizione p-1 " + (state.posizione == "center" ? "active" : "")} onClick={() => setState({...state, posizione: "center"})}>
                        <img src={posizione_centrata} />
                        <span className="d-block text-center">Al centro</span>
                      </div>
                    </div>

                    <div className="col-4">
                    <div className={"card card-posizione p-1 " + (state.posizione == "right" ? "active" : "")} onClick={() => setState({...state, posizione: "right"})}>
                        <img src={posizione_destra} />
                        <span className="d-block text-center">A destra</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <h5 className="h5 text-primary mb-1">Apertura</h5>
                  <div className="row">
                    <div className="col-4">
                      <div className={"card card-apertura p-1 " + (state.apertura == "left" ? "active" : "")} onClick={() => setState({...state, apertura: "left"})}>
                        <img src={apertura_sinistra} />
                        <span className="d-block text-center">A sinistra</span>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className={"card card-apertura p-1 " + (state.apertura == "right" ? "active" : "")} onClick={() => setState({...state, apertura: "right"})}>
                        <img src={apertura_destra} />
                        <span className="d-block text-center">A destra</span>
                      </div>
                    </div>
                  </div>
                </div>

                {accessories && <FormGroup sx={{ mt: 6, mb: 4 }}>
                  {accessories.map((res) => {
                    if (res.sku == "ACCP51") {
                      return (
                        <div key={res.id} className="row mb-3">
                          <div className="col-6">
                            <span className="text-primary">Verniciatura telaio lato interno:</span><br />
                            <strong>NELLA STESSA FINITURA DEL PANNELLO</strong>
                          </div>
                          <div className="col-2 text-center">
                            <strong>oppure</strong>
                          </div>
                          <div className="col-4">
                            <span className="text-primary">RAL custom</span><br />
                            <input
                              type="text"
                              className="form-control"
                              name="ral_custom_ACCP51"
                              //value={formik.values.ral_custom_ACCP60}
                              onChange={(e) => {
                                //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                //formik.setFieldValue("ral_custom_ACCP60", e.target.value);
                                setState({...state, coloreTelaioInterno: e.target.value});
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (res.sku == "ACCP60") {
                      return (
                        <div key={res.id} className="row mb-3">
                          <div className="col-6">
                            <span className="text-primary">Verniciatura maniglia lato esterno:</span><br />
                            <strong>NERO</strong>
                          </div>
                          <div className="col-2 text-center">
                            <strong>oppure</strong>
                          </div>
                          <div className="col-4">
                            <span className="text-primary">RAL custom</span><br />
                            <input
                              type="text"
                              className="form-control"
                              name="ral_custom_ACCP60"
                              //value={formik.values.ral_custom_ACCP60}
                              onChange={(e) => {
                                //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                //formik.setFieldValue("ral_custom_ACCP60", e.target.value);
                                setState({...state, coloreManigliaInterna: e.target.value});
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (res.sku == "ACCP61") {
                      return (
                        <div key={res.id} className="row mb-3">
                          <div className="col-6">
                            <span className="text-primary">Verniciatura maniglia lato interno:</span><br />
                            <strong>NERO</strong>
                          </div>
                          <div className="col-2 text-center">
                            <strong>oppure</strong>
                          </div>
                          <div className="col-4">
                            <span className="text-primary">RAL custom</span><br />
                            <input
                              type="text"
                              className="form-control"
                              name="ral_custom_ACCP61"
                              //value={formik.values.ral_custom_ACCP61}
                              onChange={(e) => {
                                //dispatch(baseActions.setAltezzaPortina(e.target.value));
                                //formik.setFieldValue("ral_custom_ACCP61", e.target.value);
                                setState({...state, coloreManigliaEsterna: e.target.value});
                              }}
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div key={res.id}>
                        {res.type === "checkbox" && (
                          <CheckboxCustom
                            selected={res.selected}
                            value={res.sku}
                            name={res.sku}
                            label={res.name}
                            axiosConfig={axiosConfig}
                            idAcc={res.id}
                            quote={count.quoteId}
                          />
                        )}
                      </div>
                    );
                  })}
                </FormGroup>}

                {errorVincolo && <Alert severity="error">{errorVincolo}</Alert>}

                <div className={"row mt-3"}>
                  
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-secondary w-full"
                      onClick={() => navigateToPreviuosStep()}>
                      INDIETRO
                    </button>
                  </div>
                  
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-primary w-full"
                      onClick={() => submit()}>
                      AVANTI
                      {/* {loading ? (
                        <CircularProgress style={{ color: "white" }} size={20} />
                      ) : (
                        "AVANTI"
                      )} */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </GridPreventivi>
      </Grid>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

export default PortinaPedonaleIntegrata;
