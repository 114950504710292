import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DragSource, DropTarget } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { useLocation } from "react-router-dom";

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};

const Card = forwardRef(function Card(
  {
    img,
    onClickCustom,
    index,
    applied,
    isDragging,
    connectDragSource,
    connectDropTarget,
    key,
    type,
  },
  ref
) {
  const elementRef = useRef(null);
  const [change, setChange] = useState();
  connectDragSource(elementRef);
  connectDropTarget(elementRef);
  const opacity = isDragging ? 0 : 1;
  const location = useLocation();

  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));

  useEffect(() => {
    applied === true && setChange(true);
  }, []);

  return (
    <div
      onClick={() => {
        onClickCustom();
        setChange(!change);
      }}
      ref={elementRef}
      style={{ ...style, opacity }}>
      {change === true ? (
        <>
          <p className={"mb-2"}>Trascina per cambiare la posizione</p>
          <img className={"object-contain h-24 w-full"} src={img} alt={"img"} />
        </>
      ) : (
        <div className={"h-12 flex flex-col justify-center "}>
          <p>
            Clicca qui per inserire una{" "}
            {location.pathname === "/finestrature" ? "finestra" : "griglia"}
          </p>
        </div>
      )}
    </div>
  );
});

export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
        applied: props.applied,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Card)
);
