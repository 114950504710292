import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  Card,
  CardActions,
  CardMedia,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import img from "../../assets/img/porta.png";
import { connect, useDispatch, useSelector } from "react-redux";
import DropMenu from "../../components/DropMenu/dropMenu";
import CustomizedSnackbars from "../../components/Message/Snackars";
import { useEffect, useState } from "react";
import { importBaseActions } from "../../store/reducers/base";
import { useNavigate } from "react-router-dom";
import { getProductName } from "../../Utility";

const CartellaPreventivi = ({ data }) => {
  const state = useSelector(state => state.base);
  const dispatch = useDispatch();
  const baseActions = importBaseActions();

  //const history = useHistory();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  const getProductTitle = (res) => {
    let productName = getProductName(res.category_id);
    if (res.custom_client_name) {
      productName += ` - ${res.custom_client_name}`;
    }
    return <span>- {productName}</span>;
  }

  useEffect(() => {
    // count.success === true && handleClick();
    // dispatch.count.setSuccess(false);
  }, []);

  return (
    <div className="row px-3">
      {data.map((res, index) => (
        <div key={res.id} className="col-md-6 col-xl-2 mb-3">
          <div className="card rounded-3 card-quote" onClick={() => {
            dispatch(baseActions.clearStorage());
            dispatch(baseActions.setQuoteId(res.id));
            dispatch(baseActions.setIdPorta(res.category_id));
            if (!res.category_id || Number(res.category_id) <= 0) {
              navigate("/nuova-configurazione");
            } else {
              if (res.urlPreview) {
                navigate("/generaPDF");
              } else {
                navigate("/dimensioni");
              }
            }
          }} style={{position: "relative"}}>
            <div style={{overflow: "hidden"}}>
              <img src={res.urlPreview || img} className="card-img-top" />
            </div>
            {/*<div className="card-body d-flex align-items-center">*/}
              {/*<span className={"badge " + (res.urlPreview ? "bg-success" : "bg-warning text-dark")}>#{res.id} {res.category_id && <>- {getProductName(res.category_id)}</>}</span>*/}
              {/* <span className="ms-3">{(state.customer ? state.customer.lastName + " " + state.customer.firstName : state.user.lastName + " " + state.user.firstName)}</span> */}
            {/*</div>*/}

            <div style={{position: "absolute", left: ".5rem", top: ".25rem"}}>
              <span className={"badge " + (res.urlPreview ? "bg-success" : res.category_id && Number(res.category_id) > 0 ? "bg-warning text-dark" : "bg-danger")}>#{res.id} {res.category_id && getProductTitle(res)}</span>
              {/* <span className="ms-3">{(state.customer ? state.customer.lastName + " " + state.customer.firstName : state.user.lastName + " " + state.user.firstName)}</span> */}
            </div>
          </div>
        </div>
      ))}
      <CustomizedSnackbars open={open} setOpen={setOpen} />
    </div>
  );
};

export default CartellaPreventivi;