import * as React from "react";
import Radio from "@mui/material/Radio";
import img from "../../assets/img/fotoPort.png";
import { Typography } from "@mui/material";
import Loading from "../Loading/loading";
import _ from "lodash";
import { useEffect } from "react";

const RadioItemDimensioni = ({
  modalValueLarghezza,
  setModalValueLarghezza,
  modalValueAltezza,
  setModalValueAltezza,
  data,
  count,
}) => {
  const handleChangeLarghezza = (event) => {
    setModalValueLarghezza(event.target.name);
  };
  const handleChangeAltezza = (event) => {
    setModalValueAltezza(event.target.name);
  };

  if (!data) return null;

  return (
    <div className={"flex flex-col"}>
      {!_.isEmpty(data.lunghezza) && (
        <>
          <Typography
            color={"primary"}
            sx={{
              textAlign: "start",
              fontSize: 20,
            }}>
            {data.lunghezza.length > 1 && <span>Scegli Larghezza* - (Larghezza foro inserita: {count.larghezza})</span>}
            {data.lunghezza.length <= 1 && <span>Larghezza foro inserita: {count.larghezza}</span>}
          </Typography>
          <div className={"grid grid-cols-1 mb-8 mt-2"}>
            {data.lunghezza && data.lunghezza.length > 0 &&data.lunghezza.map((res, index) => {
              return (
                <div key={`${res.id}_${index}`}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      fontSize: 15,
                    }}>
                    {res.warning}
                  </Typography>
                  {!res.warning && (
                    <div className={"flex flex-row align-center cursor-pointer"}>
                      {res.photo && (
                        <div>
                          <img
                            style={{
                              width: 80,
                              height: 80,
                              marginRight: 6,
                              marginTop: 3,
                              cursor: "pointer",
                            }}
                            src={res.photo}
                            alt={"imgLeft"}
                          />
                        </div>
                      )}
                      <div className={"flex flex-col justify-center"}>
                        <Typography
                          sx={{
                            fontSize: 18,
                          }}>
                          {res.title}
                        </Typography>
                      </div>
                      <div className={"flex items-center"}>
                        <Radio
                          checked={
                            modalValueLarghezza === res.name ||
                            data.lunghezza.length === 1
                          }
                          onChange={handleChangeLarghezza}
                          value={res.id}
                          name={res.name}
                          style={{visibility: data.lunghezza.length === 1 ? "hidden" : "visible"}}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
      {!_.isEmpty(data.altezza) && (
        <>
          <Typography
            color={"primary"}
            sx={{
              textAlign: "start",
              fontSize: 20,
            }}>
            {data.altezza.length > 1 && <span>Scegli Altezza* - (Altezza foro inserita: {count.altezza})</span>}
            {data.altezza.length <= 1 && <span>Altezza foro inserita: {count.altezza}</span>}
          </Typography>
          <div className={"grid grid-cols-1 mb-8 mt-2"}>
            {data.altezza && data.altezza.length > 0 && data.altezza.map((res, index) => {
              return (
                <div key={`${res.id}_${index}`}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      fontSize: 15,
                    }}
                  >
                    {res.warning}
                  </Typography>
                  {!res.warning && (
                    <div className={"flex flex-row align-center cursor-pointer"}>
                      {res.photo && (
                        <div>
                          <img
                            style={{
                              width: 80,
                              height: 80,
                              marginRight: 6,
                              marginTop: 3,
                              cursor: "pointer",
                            }}
                            src={res.photo}
                            alt={"imgLeft"}
                          />
                        </div>
                      )}
                      <div className={"flex flex-col justify-center"}>
                        <Typography
                          sx={{
                            fontSize: 18,
                          }}>
                          {res.title}
                        </Typography>
                      </div>
                      <div className={"flex items-center"}>
                        <Radio
                          checked={
                            modalValueAltezza === res.name ||
                            data.altezza.length === 1
                          }
                          onChange={handleChangeAltezza}
                          value={res.id}
                          name={res.name}
                          style={{visibility: data.altezza.length === 1 ? "hidden" : "visible"}}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default RadioItemDimensioni;
