import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import img from "../../assets/img/image11.png";
import axios from "axios";
import useSWR from "swr";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Loading from "../../components/Loading/loading";
import { importBaseActions } from "../../store/reducers/base";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getProductName } from "../../Utility";

const Porte = () => {
  const state = useSelector(state => state.base);
  const dispatch = useDispatch();
  const baseActions = importBaseActions();
  //const history = useHistory();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  useEffect(() => load(), []);
  
  const load = async () => {
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/categories`, { customer_id: state.customer ? state.customer.id : state.user.id, quote_id: state.quoteId }, axiosConfig);
      if (res.data.success == 1) {        
        let porte = res.data.data.filter(p => p.id == 2 || p.id == 3);
        // if (overlap && overlap.length > 0) {
        //   setPorta(overlap);
        // } else {
          setData(porte);
        // }
      }
    } catch (e) {
      setError(e);
    }
  }

  const setPorta = async (porta) => {
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/save-category`, { 
        quote_id: porta.quoteId,
        category_id: porta.id 
      }, axiosConfig);
      
      if (res.data.success == 1) {
        dispatch(baseActions.setIdPorta(porta.id));
        dispatch(baseActions.setQuoteId(porta.quoteId));

        navigate("/dimensioni");
      }
    } catch (e) {
      setError(e);
    }
  }

  if (error) return <div>Errore nel caricamento...</div>;
  if (!data) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      {!data ? (
        <Loading />
      ) : (
        <>
          <div className={"d-flex justify-content-between p-3"}>
            <div className="d-flex flex-column" style={{lineHeight: "1"}}>
              <h2 className="h2 fw-bold">
                Nuova configurazione {getProductName(data.idPorta)} (#{data.quoteId})
              </h2>
            </div>
            <div>
              <button className="btn btn-secondary me-3" type="button" onClick={() => navigate(-1)}>
                <CloseIcon /> 
                ANNULLA
              </button>
            </div>
          </div>
          <Grid
            container
            sx={{
              height: "80%",
              // width: "100%",
              // mx: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
            spacing={2}
          >
            {data.map((el, index) => {
              return (
                <Grid
                  key={index}
                  item
                  onClick={() => setPorta(el)}
                  xs={12}
                  md={2}
                  spacing={4}
                  sx={{
                    // display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    paddingBottom: 10,
                  }}
                >
                  <Card
                    sx={{
                      // width: 150,
                      boxShadow: 5,
                      cursor: "pointer",
                      "&:hover": { boxShadow: 20 },
                    }}
                  >
                    <div className={"flex justify-center w-full mt-6"}>
                      <img
                        className={"object-contain"}
                        src={el.img}
                        alt={"img"}
                      />
                    </div>
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography
                        variant="h5"
                        component="div"
                        fontWeight={"bold"}
                        fontSize={18}
                      >
                        {el.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </LayoutPage>
  );
};

export default Porte;
