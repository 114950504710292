import * as React from "react";
import Grid from "@mui/material/Grid";
import { InputCustom } from "../Input/InputCustom";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import {
  Alert,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import useSWR from "swr";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import { useEffect } from "react";

const validationSchemaAddUser = yup.object({
  nome: yup.string().required("Campo necessario"),
  cognome: yup.string().required("Campo necessario"),
  r_sociale: yup.string().required("Campo necessario"),
  email: yup.string().required("Campo necessario").email("Formato non valido"),
  cell: yup
    .number()
    .required("Campo necessario")
    .typeError("Inserisci un numero di cellulare valido")
    .positive("Inserisci un numero intero")
    .integer("Inserisci un decimale")
    .min(8),
  indirizzo: yup.string().required("Campo necessario"),
  cap: yup.string().required("Campo necessario"),
  city: yup.string().required("Campo necessario"),
  p_iva: yup.string().required("Campo necessario"),
  scontistica: yup
    .number()
    .required("Campo necessario")
    .typeError("Inserisci un numero intero"),
});

const ClientiForm = ({ user, handleClose }) => {
  const state = useSelector(state => state.base);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + state.user.token,
    },
  };

  // const fetcher = (url) =>
  //   axios.get(url, axiosConfig).then((res) => res.data.data);
  // const { data, error } = useSWR(
  //   user && [`${process.env.REACT_APP_API_URL}/customers/${user}/edit`, count],
  //   fetcher
  // );

  useEffect(() => load(), []);

  const load = async () => {
    if (!user) return;
    try
    {
      let res = await axios.get(`${process.env.REACT_APP_API_URL}/customers/${user}`, axiosConfig);
      setData(res.data.data);
    }
    catch (e)
    {
      setError(e);
    }
  }

  if (error) return <div>failed to load</div>;
  if (!data && user)
    return <CircularProgress />;
  return (
    <Formik
      initialValues={{
        nome: data && user ? data.name : "",
        cognome: data && user ? data.surname : "",
        r_sociale: data && user ? data.business_name : "",
        email: data && user ? data.email : "",
        cell: data && user ? data.phone : "",
        indirizzo: data && user ? data.address : "",
        cap: data && user ? data.cap : "",
        city: data && user ? data.city : "",
        p_iva: data && user ? data.piva : "",
        scontistica: data && user ? data.discount : "",
      }}
      onSubmit={(values) => {
        setLoading(true);

        user && Number(user) > 0
          ? axios
              .put(
                `${process.env.REACT_APP_API_URL}/customers/${user}`,
                {
                  user_id: state.user.id,
                  name: values.nome,
                  surname: values.cognome,
                  business_name: values.r_sociale,
                  email: values.email,
                  phone: values.cell,
                  address: values.indirizzo,
                  cap: values.cap,
                  city: values.city,
                  piva: values.p_iva,
                  discount: values.scontistica,
                },
                axiosConfig
              )
              .then((res) => {
                if (res.data.data === 1) {
                  setLoading(true);

                  //dispatch.count.setSuccess(true);
                  handleClose();
                } else {
                  res.data.data.email && setOpen(true);
                }
              })
              .then(() => setLoading(false))
              .catch(function (error) {
                console.log(error);
              })
          : axios
              .post(
                `${process.env.REACT_APP_API_URL}/customers`,
                {
                  user_id: state.agent ? state.agent.id : state.user.id,
                  name: values.nome,
                  surname: values.cognome,
                  business_name: values.r_sociale,
                  email: values.email,
                  phone: values.cell,
                  address: values.indirizzo,
                  cap: values.cap,
                  city: values.city,
                  piva: values.p_iva,
                  discount: values.scontistica
                },
                axiosConfig
              )
              .then((res) => {
                if (res.data.success === 1) {
                  setLoading(true);

                  //dispatch.count.setSuccess(true);
                  handleClose();
                } else {
                  res.data.data.email && setOpen(true);
                }
              })
              .then(() => setLoading(false))
              .catch(function (error) {
                console.log(error);
              });
      }}
      validationSchema={validationSchemaAddUser}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        setFieldTouched,
        setFieldValue,
        touched,
      }) => (
        <>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
              variant="filled"
              severity="warning"
            >
              Email già presente. Riprovare con un'altra mail
            </Alert>
          </Collapse>
          <div className="row">
            <div className="col-xl-6">
              <Input type={"hidden"} name={"id"} value={values.id} />
              <InputCustom
                name={"nome"}
                label={"Nome*"}
                placeholder={"Inserisci il tuo nome..."}
                formik={values}
                error={touched.nome && Boolean(errors.nome)}
                value={values.nome}
                helperText={touched.nome && errors.nome}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-6">
              <InputCustom
                name={"cognome"}
                label={"Cognome*"}
                placeholder={"Inserisci il tuo cognome..."}
                error={touched.cognome && Boolean(errors.cognome)}
                value={values.cognome}
                helperText={touched.cognome && errors.cognome}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <InputCustom
                name={"r_sociale"}
                label={"Ragione sociale*"}
                placeholder={"Inserisci la tua ragione sociale..."}
                formik={values}
                error={touched.r_sociale && Boolean(errors.r_sociale)}
                value={values.r_sociale}
                helperText={touched.r_sociale && errors.r_sociale}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <InputCustom
                name={"email"}
                label={"Email*"}
                placeholder={"Inserisci la tua email..."}
                formik={values}
                error={touched.email && Boolean(errors.email)}
                value={values.email}
                helperText={touched.email && errors.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-6">
              <InputCustom
                name={"cell"}
                label={"Numero di telefono*"}
                placeholder={"Inserisci il tuo telefono..."}
                error={touched.cell && Boolean(errors.cell)}
                value={values.cell}
                helperText={touched.cell && errors.cell}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <InputCustom
                name={"indirizzo"}
                label={"Indirizzo*"}
                placeholder={"Inserisci il tuo indirizzo..."}
                formik={values}
                error={touched.indirizzo && Boolean(errors.indirizzo)}
                value={values.indirizzo}
                helperText={touched.indirizzo && errors.indirizzo}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <InputCustom
                name={"cap"}
                label={"CAP*"}
                placeholder={"Inserisci il tuo CAP..."}
                formik={values}
                error={touched.cap && Boolean(errors.cap)}
                value={values.cap}
                helperText={touched.cap && errors.cap}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-6">
              <InputCustom
                name={"city"}
                label={"Città*"}
                placeholder={"Inserisci la tua città..."}
                error={touched.city && Boolean(errors.city)}
                value={values.city}
                helperText={touched.city && errors.city}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <InputCustom
                name={"p_iva"}
                label={"P.IVA*"}
                placeholder={"Inserisci la tua P.IVA..."}
                formik={values}
                error={touched.p_iva && Boolean(errors.p_iva)}
                value={values.p_iva}
                helperText={touched.p_iva && errors.p_iva}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-6">
              <InputCustom
                name={"scontistica"}
                label={"Scontistica limite*"}
                placeholder={"Inserisci scontistica limite..."}
                formik={values}
                error={touched.scontistica && Boolean(errors.scontistica)}
                value={values.scontistica}
                helperText={touched.scontistica && errors.scontistica}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ fontWeight: "bold" }} position="end">
                      %
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-end">
              {loading === true ? (
                <CircularProgress />
              ) : (
                <>                
                  <button type="button" className="btn btn-secondary me-2" onClick={handleClose}>
                    <CloseIcon /> CANCELLA
                  </button>

                  <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    <SaveIcon /> CONFERMA
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default ClientiForm;
