import * as React from "react";
import Tab from "@mui/material/Tab";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { makeStyles } from "@mui/styles";

const TabCustom = ({
  count,
  index,
  nome,
  IMG,
  img,
  id,
  rottaSucc,
  rottaPrec,
  label,
  IdFinale,
}) => {
  const location = useLocation();
  //const history = useHistory();
  // const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Tab
      // className={classes.root}
      key={index}
      disabled={location.pathname !== `/${nome}` && count[nome] === false}
      onClick={() => {
        if (!count[nome]) return;
        if (location.pathname == `/${nome}`) return;

        navigate(`/${nome}`);
      }}
      sx={
        location.pathname === `/${nome}` && count[nome] === false
          ? {
              opacity: 1,
              textTransform: "none",
              fontWeight: "bold",
              color: "#dc3d22",
              justifyContent: "flex-start",
              maxWidth: "10%",
              borderBottom: "2px solid #dc3d22",
              marginRight: 1,
            }
          : location.pathname !== `/${nome}` && count[nome] === false
          ? {
              opacity: 1,
              maxWidth: "10%",
              textTransform: "none",
              fontWeight: "bold",
              justifyContent: "flex-start",
              borderBottom: "2px solid #D5D5D5",
              color: "#979797",
              marginRight: 1,
            }
          : location.pathname === `/${nome}` && count[nome] === true
          ? {
              opacity: 1,
              maxWidth: "10%",
              textTransform: "none",
              fontWeight: "bold",
              color: "#dc3d22",
              justifyContent: "flex-start",
              borderBottom: "2px solid #dc3d22",
              marginRight: 1,
            }
          : location.pathname !== `/${nome}` &&
            count[nome] === true && {
              opacity: 1,
              textTransform: "none",
              fontWeight: "bold",
              maxWidth: "10%",
              justifyContent: "flex-start",
              color: "#19C35D",
              borderBottom: "2px solid #19C35D",
              marginRight: 1,
            }
      }
      icon={
        <>
          {location.pathname === `/${nome}` || count[nome] === true ? (
            <div
              className={
                "p-4 bg-white rounded-lg border-2 border-gray-200 shadow-inner"
              }>
              <img
                onClick={() => {/*
                  debugger
                  navigate(`/${nome}`, { id: count.idPorta })
                */}}
                src={img}
                style={{ width: 65 }}
                alt={"img2"}
              />
            </div>
          ) : (
            <div
              className={
                "p-4 bg-white rounded-lg border-2 border-gray-200 shadow-inner"
              }>
              <img
                onClick={() => {/* navigate(`/${nome}`, { id: count.idPorta })*/ }}
                src={img}
                alt={"img2"}
                style={{ width: 65 }}
              />
            </div>
          )}
        </>
      }
      label={label}
    />
  );
};
/*
const useStyles = makeStyles({
  root: {
    "&.css-j1ts4m-MuiButtonBase-root-MuiTab-root": {
      opacity: 1,
    },
    "&.css-150htc8-MuiButtonBase-root-MuiTab-root": {
      opacity: 1,
    },
  },
});*/

export default TabCustom;
