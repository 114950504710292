import * as React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputCustom } from "../../components/Input/InputCustom";
import { Auth } from "../../components/Auth/auth";
import "../../assets/css/index.css";
import { IconButton, Typography } from "@mui/material";
import "@fontsource/titillium-web/700.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Il formato non è corretto")
    .required("Email obbligatoria"),
});

export const RecuperaPass = () => {
  //const history = useHistory();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/forgot-password`, { email: values.email })
        .then(
          (res) => res.data.success === 1 && /*history.push("/recupera-success")*/navigate("/recupera-success")
        )
        .catch(function (error) {
          console.log(error);
        })
        .then(() => setLoading(false));
    },
  });

  return (
    <Auth
      header={
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton sx={{ my: "auto" }} aria-label="delete">
            <ArrowBackIcon
              onClick={() => /*history.goBack()*/navigate(-1)}
              fontSize={"large"}
              sx={{ color: "black" }}
            />
          </IconButton>
          <Typography fontWeight={700} variant="h3" component="div">
            Recupera password
          </Typography>
        </Grid>
      }
    >
      <Typography
        sx={{
          py: 5,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
        fontWeight={400}
        variant="h5"
        component="div"
      >
        Inserisci qui il tuo indirizzo e-mail di registrazione. Ti invieremo una
        nuova password con cui accedere
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <InputCustom
          name={"email"}
          label={"E-mail*"}
          placeholder={"Inserisci la tua email..."}
          error={formik.touched.email && Boolean(formik.errors.email)}
          value={formik.values.email}
          helperText={formik.touched.email && formik.errors.email}
          onChange={formik.handleChange}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link
              sx={{ py: 1, cursor: "pointer" }}
              onClick={() => /*history.goBack()*/navigate(-1)}
            >
              Torna al login
            </Link>
          </Grid>
        </Grid>
        {!loading ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              py: 1.5,
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            RECUPERA PASSWORD
          </Button>
        ) : (
          <Box sx={{ display: "flex", mt: 5, justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </form>
    </Auth>
  );
};
