import * as React from "react";
import Grid from "@mui/material/Grid";
import { InputCustom } from "../Input/InputCustom";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import * as yup from "yup";
import { useFormik } from "formik";
import { Input } from "@mui/material";
import * as Yup from "yup";

const validationSchema = yup.object({
  password: Yup.string().required("Password necessaria"),
  newPassword: Yup.string().required("Password necessaria"),
  confirm: Yup.string()
    .required("Password necessaria")
    .oneOf([Yup.ref("newPassword"), null], "La password non coincide"),
});

export const ResetPass = ({ user, handleClose }) => {
  const formik = useFormik({
    initialValues: {
      id: user.id,
      password: "",
      newPassword: "",
      confirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <form
      style={{
        justifyContent: "center",
        marginBottom: 5,
      }}
      onSubmit={formik.handleSubmit}
    >
      <InputCustom
        name={"password"}
        label={"Password"}
        type={"password"}
        placeholder={"Inserisci la tua password..."}
        error={formik.touched.password && Boolean(formik.errors.password)}
        value={formik.values.password}
        helperText={formik.touched.password && formik.errors.password}
        onChange={formik.handleChange}
      />
      <Grid
        container
        spacing={{ xs: 0, md: 3 }}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <Input type={"hidden"} name={"id"} value={formik.values.id} />
          <InputCustom
            name={"newPassword"}
            label={"Nuova password"}
            type={"password"}
            placeholder={"Inserisci la nuova password..."}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            value={formik.values.newPassword}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <InputCustom
            name={"confirm"}
            label={"Conferma password"}
            type={"password"}
            placeholder={"Conferma la tua nuova password..."}
            error={formik.touched.confirm && Boolean(formik.errors.confirm)}
            value={formik.values.confirm}
            helperText={formik.touched.confirm && formik.errors.confirm}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            startIcon={<CloseIcon sx={{ fontSize: 28 }} color={"white"} />}
            color={"secondary"}
            onClick={handleClose}
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              py: 1.5,
              fontWeight: "bold",
              fontSize: 16,
              color: "white",
            }}
          >
            CANCELLA
          </Button>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            startIcon={<SaveAltIcon sx={{ fontSize: 28 }} color={"white"} />}
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              py: 1.5,
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            AGGIORNA
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
