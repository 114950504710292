import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { MenuItem, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ItemAccessori = ({
  index1,
  img,
  name,
  price,
  qty_max,
  id,
  quantity,
  count,
  axiosConfig,
}) => {
  const [qty, setQty] = useState(quantity);
  const location = useLocation();
  const handleChangeModal = (event) => {
    setQty(event.target.value);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/save-accessories`,
        {
          id: id,
          qty: event.target.value,
          quote: count,
          slug: location.pathname.substring(1),
        },
        axiosConfig
      )
      .then((res) => res.data.data)
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="row mt-3">
      <div className="col-2">
        {img && <img
          style={{ width: 80, height: 80, objectFit: "contain" }}
          src={img}
          alt={"img"}
        />}
      </div>

      <div className="col-6">
        <Typography variant={"h7"}>{name}</Typography>
      </div>

      <div className="col-2 text-end">
        <Typography fontWeight={700} variant={"h7"}>
          € {price}
        </Typography>
      </div>

      <div className="col-2">
        <TextField
          select
          fullWidth
          label={(qty_max == 1 ? "Aggiungi" : "Quantità")}
          name={"qty"}
          placeholder={(qty_max == 1 ? "Aggiungi" : "Quantità")}
          value={qty}
          onChange={handleChangeModal}>
          {[...new Array(qty_max + 1)].map((res, index) => {
            return <MenuItem value={index}>{(qty_max == 1 ? (index == 0 ? "NO" : "SI") : index)}</MenuItem>;
          })}
        </TextField>        
      </div>
    </div>
  );
};

export default ItemAccessori;
