import axios from "axios";

export function useOptionsApi() {
    return {
        getPhotoPreview: async function(token, data) {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/render-images`, data, { 
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            return response.data;
        }
    }
}