import { CircularProgress } from "@mui/material";
import React from "react";

export const LoadingInternal = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{position: "fixed", width: "100%", height: "100%", top: "0", left: "0", backgroundColor: "rgba(255,255,255,0.5)"}}>
          <div>
            <CircularProgress />
          </div>
        </div>
    );
}