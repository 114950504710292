import { useConfigurationsApi } from "../api/ConfigurationsApi"

export const useConfigurationsService = () => {
    const configurationsApi = useConfigurationsApi();

    return {
        async createQuickConfiguration(token, data) {
            let result = await configurationsApi.createQuickConfiguration(token, data);
            if (result.success <= 0) throw result.message;

            return result.data;
        }
    }
}