import axios from "axios";

export const useConfigurationsApi = () => {
    return {
        async createQuickConfiguration(token, data) {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/create-quick-configuration`, data, { 
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            debugger

            return response.data;
        }
    }
}