import * as React from "react";
import Grid from "@mui/material/Grid";
import TopBar from "../../components/TopBar/TopBar";
import { LayoutPage } from "../../components/Layout/layoutPage";
import {
  Button,
  FormGroup,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TabsCustom from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridPreventivi from "../../components/GrdiPreventivi/gridPreventivi";
import axios from "axios";
import useSWR from "swr";
import Loading from "../../components/Loading/loading";
import CheckboxCustom from "../../components/Checkbox/CheckboxCustom";
import { importBaseActions } from "../../store/reducers/base";
import { getProductName } from "../../Utility";

const ElementiFissi = () => {
  const count = useSelector(state => state.base);
  const baseActions = importBaseActions();
  const dispatch = useDispatch();

  //const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  //const [open, setOpen] = useState(false);
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };

  //const [risultato, setRisultato] = useState("");

  const fetcher = (url) =>
    axios.post(url, { id: count.idPorta }, axiosConfig).then((res) => res.data.data);
  const { data, error } = useSWR([`${process.env.REACT_APP_API_URL}/categories-steps`], fetcher);

  const fetcher2 = (url) =>
    axios
      .post(
        url,
        {
          id: count.idPorta,
          quote: count.quoteId,
          step: location.pathname.substring(1),
        },
        axiosConfig
      )
      .then((res) => res.data.data);
  const { data: accessories, error: errorAccessories } = useSWR(
    [`${process.env.REACT_APP_API_URL}/accessories`, location.pathname],
    fetcher2
  );

  const [value, setValue] = useState();
  const handleChange = () => {
    data &&
      data.map((el) =>
        `/${el.slug}` === location.pathname ? setValue(el.id) : null
      );
  };

  useEffect(() => {
    return () => handleChange();
  }, []);

  if (error || errorAccessories) {
    console.log("AT - elementiFissi");
    return <div>failed to load</div>;
  }
  if (!data || !accessories) return <Loading />;

  return (
    <LayoutPage>
      <TopBar />
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          mx: 4,
          my: 2,
        }}
        spacing={2}
      >
        <Grid item xs={12} md={8}>
          <Grid container sx={{ alignItems: "center" }}>
            <Typography
              fontWeight={700}
              variant="h4"
              // color={"#262626"}
              component="div"
            >
              Nuova configurazione {getProductName(count.idPorta)} (#{count.quoteId})
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<CloseIcon />}
            color={"secondary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large"
          >
            ANNULLA
          </Button>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              dispatch(baseActions.clearStorage());
              navigate("/configurazioni");
            }}
            startIcon={<SaveIcon />}
            color={"primary"}
            sx={{ color: "white", fontWeight: "bold" }}
            variant="contained"
            size="large"
          >
            SALVA E ESCI
          </Button>
        </Grid>
      </Grid>

      <Grid container sx={{ height: "80%", pb: 5, justifyContent: "center" }}>
        <Grid
          className={classes.root}
          container
          sx={{ pb: 20, justifyContent: "center" }}
        >
          <TabsCustom
            value={value}
            setValue={setValue}
            id={count.idPorta}
            count={count}
            handleChange={handleChange}
            axios_conf={axiosConfig}
            url={`${process.env.REACT_APP_API_URL}/categories-steps`}
          />
        </Grid>
        <GridPreventivi
          header={null
            // <Typography variant={"h4"} fontWeight={700} mt={10}>
            //   Elementi Fissi
            // </Typography>
          }
          dataSend={{
            quote_id: count.quoteId,
            category_id: count.idPorta,
          }}
          axiosConfig={axiosConfig}
          url={`${process.env.REACT_APP_API_URL}/photo-preventivi`}
          dipendenze={false}
        >
          <FormGroup sx={{ mt: 6 }}>
            {accessories.map((res) => {
              return (
                <div key={res.id}>
                  {res.type === "checkbox" && (
                    <CheckboxCustom
                      selected={res.selected}
                      value={res.sku}
                      name={res.sku}
                      label={res.name}
                      axiosConfig={axiosConfig}
                      idAcc={res.id}
                      quote={count.quoteId}
                    />
                  )}
                </div>
              );
            })}
          </FormGroup>
          <Grid container textAlign={"center"} mt={2} spacing={2}>
            <Grid item md={6}>
              <Button
                fullWidth
                color={"secondary"}
                variant={"contained"}
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  py: 1,
                }}
                onClick={() => {
                  data &&
                    data.forEach((el, index) => {
                      `/${el.slug}` === location.pathname &&
                        navigate(
                          `/${data[index - 1] && data[index - 1].slug}`,
                          {
                            id: count.idPorta,
                          }
                        );
                    });
                }}
              >
                INDIETRO
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                fullWidth
                sx={{ color: "white", fontWeight: "bold", py: 1 }}
                variant={"contained"}
                onClick={() => {
                  dispatch(baseActions.setElementiFissi(true));
                  data &&
                    data.map(
                      (el, index) =>
                        `/${el.slug}` === location.pathname &&
                        navigate(
                          `/${data[index + 1] && data[index + 1].slug}`,
                          {
                            id: count.idPorta,
                          }
                        )
                    );
                }}
              >
                AVANTI
              </Button>
            </Grid>
          </Grid>
        </GridPreventivi>
      </Grid>
    </LayoutPage>
  );
};

const useStyles = makeStyles({
  root: {
    "& MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
});

const mapState = (state) => ({
  count: state.count,
});

export default connect(mapState)(ElementiFissi);
