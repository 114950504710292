import * as React from "react";
import { IconButton, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import Box from "@mui/material/Box";

export const ModalEdit = ({ children, title, open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalResponsive>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={6}>
            <Typography fontWeight={700} variant="h5" component="div">
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: 28 }} color={"secondary"} />
            </IconButton>
          </Grid>
        </Grid>
        {children}
      </ModalResponsive>
    </Modal>
  );
};

const ModalResponsive = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 80%;
  overflow: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;
  padding: 20px;
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;
