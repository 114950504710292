import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "../../assets/css/index.css";
import { IconButton, Typography } from "@mui/material";
import "@fontsource/titillium-web/700.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import { Auth } from "../../components/Auth/auth";
import img from "../../assets/img/recuperapass.png";
import { useNavigate } from "react-router-dom";

export const RecuperaSuccess = () => {
  //const history = useHistory();
  const navigate = useNavigate();

  return (
    <Auth
      header={
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton sx={{ my: "auto" }} aria-label="delete">
            <ArrowBackIcon
              onClick={() => /*history.goBack()*/navigate(-1)}
              fontSize={"large"}
              sx={{ color: "black" }}
            />
          </IconButton>
          <Typography fontWeight={700} variant="h3" component="div">
            Recupera password
          </Typography>
        </Grid>
      }
    >
      <Box sx={{ py: 5 }}>
        <Typography fontWeight={400} variant="h5" component="div">
          Controlla la tua casella e-mail (anche nello spam)
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <img src={img} alt={"img"} />
      </Box>
      <Button
        // type="submit"
        onClick={() => /*history.push("/login")*/navigate("/login")}
        fullWidth
        variant="contained"
        sx={{
          mt: 2,
          mb: 2,
          py: 1.5,
          fontWeight: "bold",
          fontSize: 16,
        }}
      >
        TORNA AL LOGIN
      </Button>
    </Auth>
  );
};
