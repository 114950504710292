import { makeStyles } from "@mui/styles";

export const useDataGridStyles = makeStyles({
  root: {
    "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .super-app-theme--cell": {
      fontWeight: 700,
      fontSize: 20,
    },
    "& .MuiDataGrid-root": {
      border: "none",
      borderRadius: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: 0,
    },
    "& .MuiDataGrid-row": {
      // border: "1px solid #88888866",
      //boxShadow: "0px 0px 4px 1px #88888866",
      //marginLeft: 10,
      //marginTop: 10,
      //marginRight: 10,
      //marginBottom: 10,
      //borderRadius: 10,
      //width: "99%",
      borderBottom: "solid 1px rgba(224, 224, 224, 1)"
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "inherit !important",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      borderBottom: "transparent",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
});


export const usePaginationStyles = makeStyles({
  root: {
    display: "flex",
    marginBottom: 60,
    marginTop: 5,
  },
});

export const useDropDownMenuStyles = makeStyles({
  root: {
    "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .super-app-theme--cell": {
      fontWeight: 700,
      fontSize: 20,
    },
    "& .MuiDataGrid-root": {
      border: "none",
      borderRadius: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: 0,
    },
    "& .MuiDataGrid-row": {
      // border: "1px solid #88888866",
      //boxShadow: "0px 0px 4px 1px #88888866",
      //marginLeft: 10,
      //marginTop: 10,
      //marginRight: 10,
      //marginBottom: 10,
      //borderRadius: 10,
      //width: "99%",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "inherit !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      borderBottom: "transparent",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    '& .MuiPaper-root': {
      boxShadow: '0 0 10px rgba(0,0,0,0.3) !important',
      fontSize: "1rem",
      marginTop: '.5rem',
      '& .MuiMenu-list': {

      },
      '& .MuiMenuItem-root': {
        paddingTop: "0px",
        '& .MuiSvgIcon-root': {
          fontSize: "1rem",
          marginRight: ".5rem"
        }
      }
    }
  },
});