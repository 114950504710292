export const OptionsTypeEnum = {
    PLANA40_CODE: "M40",
    PLANA75_CODE: "M75",
    PLANA_FG_CODE: "MOP",
    AIR_CODE: "M01",
    AIR_OVERBAS_CODE: "MB1",
    WIDE_CODE: "M03",
    WIDE_OVERBAS_CODE: "MB3",
    LAMIERA810_CODE: "F84",
    WOODSTYLE_CODE: "F04",

    LAMIERA810: 63,
    COLOR: 59,
    MATERIAL: 60,
    WOOD: 61,
    PIETRAVERA: 62,
    FULLGLASS_VETRO_LAMINATO: 93,
    FULLGLASS_VETRO_CAMERA: 115,

    //Modelli
    PLANAFULLGLASS: 75,

    //Installazione    
    INLUCE: 7,
    OLTRELUCE: 8
}