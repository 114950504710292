import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    IMPOSTAZIONI: "SETTING",
    ASSISTENZA: "ASSISTANCE",
    I_tuoi_agenti_rivenditori: "",
    agents: "Agents",
    areaManagers: "Area Managers",
    NUOVO_AGENTE: "",
    Nome_agente: "",
    Data_ultima_modifica: "",
    Clienti: "",
    Scontistica_generica: "",
    MODIFICA: "EDIT",
    RESET_PASSWORD: "RESET PASSWORD",
    ELIMINA: "DELETE",
    CLIENTI_ARCHIVIATI: "",
    GESTIONE_SCONTI: "",
    MODIFICA_AGENTE: "",
    Ordina_per: "",
    Preventivi: "",
    ARCHIVIA_CLIENTE: "",
    MODIFICA_CLIENTE: "",
    NUOVO_PREVENTIVO: "",
    Crea_il_tuo_primo_preventivo: "",
  },
};
