import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ItemAccessori from "./ItemAccessori";
import Loading from "../Loading/loading";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { OptionsTypeEnum } from "../../core/OptionsTypeEnum";

const DialogAccessories = ({
  axiosConfig,
  open,
  setOpen,
  scroll,
  reload,
  data,
}) => {
  const count = useSelector(state => state.base);
  

  const location = useLocation();
  const handleClose = () => setOpen(false);
  const descriptionElementRef = useRef(null);
  const [Acc, setAcc] = useState();
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/accessories`,
        {
          id: count.idPorta,
          quote: count.quoteId,
          step: location.pathname.substring(1),
        },
        axiosConfig
      )
      .then((res) => {
        setAcc(res.data.data);
      });
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open, reload]);

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <Typography fontWeight={700} variant={"h5"}>
            Scegli gli accessori
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {Acc && Acc.map((res, index) => {
              if (!count.isPorticina && res.sku == "ACCM08") return null;
              
              return (
                <ItemAccessori
                  key={res.id}
                  id={res.id}
                  index1={index}
                  name={res.name}
                  img={res.img}
                  price={res.price}
                  qty_max={res.qty_max}
                  count={count.quoteId}
                  axiosConfig={axiosConfig}
                  quantity={
                    res.qty_selected !== null ? res.qty_selected : 0
                  }
                />
              )
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingX: 2 }}>
          <Button
            color={"secondary"}
            fullWidth
            variant={"contained"}
            sx={{ fontWeight: "bold", color: "white" }}
            onClick={handleClose}>
            ANNULLA
          </Button>
          <Button
            sx={{ fontWeight: "bold" }}
            fullWidth
            variant={"contained"}
            onClick={() => {
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/save-accessories`,
                  {
                    quote: count.quoteId,
                  },
                  axiosConfig
                )
                .then((res) => console.log(res.data.data))
                .catch(function (error) {
                  console.log(error);
                })
                .then(() => setOpen(false));
            }}
          >
            SCEGLI
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogAccessories;
