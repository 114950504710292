import { useCustomersApi } from "../api/customers_api"
import { CollectionResult, NullableNumber } from "../core/common_types";
import { Customer } from "../models/customer";
import { User } from "../models/user";

export const useCustomersService = () => {
    const customersApi = useCustomersApi();

    return {
        async search(currentUser: User, searchText: string, page: number, pageSize: number): Promise<CollectionResult<Customer>> {
            let result = await customersApi.search(currentUser, searchText, page, pageSize);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        },
        async findServiceCustomerId(currentUser: User, parentId: number): Promise<NullableNumber> {
            let result = await customersApi.findServiceCustomerId(currentUser, parentId);

            if (!result.success) {
                throw result.message;
            }

            return result.data;
        }
    }
}