import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as React from "react";
import { LayoutPage } from "../Layout/layoutPage";
import img from "../../assets/img/loginImg.png";
import "../../assets/css/Login.css";

export const Auth = ({ header, children }) => {
  return (
    <LayoutPage>
      <div className={"row h-full"} style={{"--bs-gutter-x": "0"}}>
        <div className="col-xl-7 d-none d-xl-block h-full">
          <img src={img} alt={"img"} className="h-full" style={{objectFit: "cover", objectPosition: "center right"}} />
        </div>
        <div className={"col-xl-5 d-flex justify-content-center"}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {header}
              <Box
                sx={{
                  mt: 5,
                  maxWidth: "400px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {children}
              </Box>
            </Box>
          </Grid>
        </div>
      </div>
    </LayoutPage>
  );
};
