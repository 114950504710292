var listeners = [];

export const Events = {
    configurationChanged: "CONFIGURATION_CHANGED"
};

export const useEventBroadcast = () => 
{
    return {
        subscribe: (senderName, eventName, callback) => 
        {
            let findRes = listeners.find(l => l.senderName == senderName && l.eventName == eventName);
            if (findRes) return;

            listeners.push({
                senderName, 
                eventName, 
                callback
            });
        },
        unsubscribe: (senderName, eventName) => 
        {
            listeners = listeners.filter(l => l.senderName != senderName && l.eventName != eventName);
        },
        dispatch: (eventName, args) => 
        {
            listeners.filter(l => l.eventName == eventName).forEach(l => l.callback(args));
        }
    }
}