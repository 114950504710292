import * as React from "react";
import Grid from "@mui/material/Grid";
import { InputCustom } from "../Input/InputCustom";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Alert,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import useSWR from "swr";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import Thumb from "../Thumb/thumb";

const validationSchemaAddUser = yup.object({
  nome: yup.string().required("Campo necessario"),
  cognome: yup.string().required("Campo necessario"),
  r_sociale: yup.string().required("Campo necessario"),
  email: yup.string().required("Campo necessario").email("Formato non valido"),
  cell: yup
    .number()
    .required("Campo necessario")
    .typeError("Inserisci un numero di cellulare valido")
    .positive("Inserisci un numero intero")
    .integer("Inserisci un decimale")
    .min(8),
  indirizzo: yup.string().required("Campo necessario"),
  cap: yup.string().required("Campo necessario"),
  city: yup.string().required("Campo necessario"),
  p_iva: yup.string().required("Campo necessario"),
  scontistica: yup
    .number()
    .typeError("Inserisci un numero intero")
    .required("Campo necessario"),
  logo: yup.mixed(),
});

const ProfileForm = ({ handleClose, count, dispatch }) => {
  const [thumb, setThumb] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + count.user.token,
    },
  };
  const fetcher = (url) =>
    axios.get(url, axiosConfig).then((res) => res.data.data);
  const { data, error } = useSWR(
    [`${process.env.REACT_APP_API_URL}/profile/me`, count.token, handleClose],
    fetcher
  );

  if (error) {
    console.log("AT - profileForm");
    return <div>failed to load</div>;
  }
  if (!data)
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        nome: data ? data.name : "",
        cognome: data ? data.surname : "",
        r_sociale: data ? data.business_name : "",
        email: data ? data.email : "",
        cell: data ? data.phone : "",
        indirizzo: data ? data.address : "",
        cap: data ? data.cap.toString() : "",
        city: data ? data.city : "",
        p_iva: data ? data.piva : "",
        scontistica: data ? data.discount : "",
        file: data ? data.logo : "",
      }}
      onSubmit={(values) => {
        setLoading(true);
        dispatch.count.setThumb(thumb);
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/users/${data.id}`,
            {
              name: values.nome,
              surname: values.cognome,
              business_name: values.r_sociale,
              email: values.email,
              phone: values.cell,
              address: values.indirizzo,
              cap: values.cap,
              city: values.city,
              piva: values.p_iva,
              discount: values.scontistica,
              logo: thumb,
            },
            axiosConfig
          )
          .then((res) => {
            if (res.data.data === 1) {
              setLoading(true);
              dispatch.count.setSuccess(true);
              handleClose();
            } else {
              res.data.data.email && setOpen(true);
            }
          })
          .then(() => setLoading(false))
          .catch(function (error) {
            console.log(error);
          });
      }}
      validationSchema={validationSchemaAddUser}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        setFieldTouched,
        setFieldValue,
        touched,
      }) => (
        <>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
              variant="filled"
              severity="warning"
            >
              Email già presente. Riprovare con un'altra mail
            </Alert>
          </Collapse>
          <Grid
            container
            spacing={{ xs: 0, md: 3 }}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <Input type={"hidden"} name={"id"} value={values.id} />
              <InputCustom
                name={"nome"}
                label={"Nome*"}
                placeholder={"Inserisci il tuo nome..."}
                formik={values}
                error={touched.nome && Boolean(errors.nome)}
                value={values.nome}
                helperText={touched.nome && errors.nome}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <InputCustom
                name={"cognome"}
                label={"Cognome*"}
                placeholder={"Inserisci il tuo cognome..."}
                error={touched.cognome && Boolean(errors.cognome)}
                value={values.cognome}
                helperText={touched.cognome && errors.cognome}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <InputCustom
            name={"r_sociale"}
            label={"Ragione sociale*"}
            placeholder={"Inserisci la tua ragione sociale..."}
            formik={values}
            error={touched.r_sociale && Boolean(errors.r_sociale)}
            value={values.r_sociale}
            helperText={touched.r_sociale && errors.r_sociale}
            onChange={handleChange}
          />
          <Grid
            container
            spacing={{ xs: 0, md: 3 }}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <InputCustom
                name={"email"}
                label={"Email*"}
                placeholder={"Inserisci la tua email..."}
                formik={values}
                error={touched.email && Boolean(errors.email)}
                value={values.email}
                helperText={touched.email && errors.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <InputCustom
                name={"cell"}
                label={"Numero di telefono*"}
                placeholder={"Inserisci il tuo telefono..."}
                error={touched.cell && Boolean(errors.cell)}
                value={values.cell}
                helperText={touched.cell && errors.cell}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <InputCustom
            name={"indirizzo"}
            label={"Indirizzo*"}
            placeholder={"Inserisci il tuo indirizzo..."}
            formik={values}
            error={touched.indirizzo && Boolean(errors.indirizzo)}
            value={values.indirizzo}
            helperText={touched.indirizzo && errors.indirizzo}
            onChange={handleChange}
          />
          <Grid
            container
            spacing={{ xs: 0, md: 3 }}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <InputCustom
                name={"cap"}
                label={"CAP*"}
                placeholder={"Inserisci il tuo CAP..."}
                formik={values}
                error={touched.cap && Boolean(errors.cap)}
                value={values.cap}
                helperText={touched.cap && errors.cap}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <InputCustom
                name={"city"}
                label={"Città*"}
                placeholder={"Inserisci la tua città..."}
                error={touched.city && Boolean(errors.city)}
                value={values.city}
                helperText={touched.city && errors.city}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 0, md: 3 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 2,
            }}
          >
            <Grid item xs={12} md={6}>
              <InputCustom
                name={"p_iva"}
                label={"P.IVA*"}
                placeholder={"Inserisci la tua P.IVA..."}
                formik={values}
                error={touched.p_iva && Boolean(errors.p_iva)}
                value={values.p_iva}
                helperText={touched.p_iva && errors.p_iva}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <InputCustom
                name={"scontistica"}
                label={"Scontistica limite*"}
                placeholder={"Inserisci scontistica limite..."}
                formik={values}
                error={touched.scontistica && Boolean(errors.scontistica)}
                value={values.scontistica}
                helperText={touched.scontistica && errors.scontistica}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ fontWeight: "bold" }} position="end">
                      %
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Typography variant={"h7"}>Inserisci il logo</Typography>
          <Box>
            <div className="form-group mt-2">
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
                className="form-control block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              />
            </div>
            <Thumb
              th={thumb ? thumb : values.file}
              changeTh={setThumb}
              file={values.file}
            />
          </Box>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                startIcon={<CloseIcon sx={{ fontSize: 28 }} color={"white"} />}
                color={"secondary"}
                onClick={handleClose}
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "white",
                }}
              >
                CANCELLA
              </Button>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {loading === true ? (
                <CircularProgress />
              ) : (
                <Button
                  // type="submit"
                  onClick={handleSubmit}
                  startIcon={<SaveIcon sx={{ fontSize: 28 }} color={"white"} />}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1.5,
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  CARICA
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(ProfileForm);
