import React from "react";
import Box from "@mui/material/Box";
import img from "../../assets/img/dimensioniPorta.png";
import logo from "../../assets/img/LogoOverlap.png";
import { Button, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useHistory, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { importBaseActions } from "../../store/reducers/base";

const ErrorCustom = ({ route, message }) => {
  const dispatch = useDispatch();
  //const count = useSelector(state => state.base);
  const baseActions = importBaseActions();

  //const history = useHistory();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img style={{ marginBottom: 15 }} src={logo} alt={"img"} />

      <Typography variant={"h4"} sx={{ marginBottom: 5 }}>
        <ErrorOutlineIcon sx={{ marginRight: 1 }} />
        {message}
      </Typography>
      <Button
        size={"large"}
        onClick={() => {
          dispatch(baseActions.clearStorage());
          //history.push(route);
          navigate("/" + route);
        }}
        variant={"contained"}
      >
        TORNA INDIETRO E RIPROVA
      </Button>
    </Box>
  );
};

export default ErrorCustom;
