import axios from "axios";

export const useAuthApi = () => {
    return {
        async changePassword(token, idUser, userType, currentPassword, newPassword) {
            let data = {
                idUser, userType, currentPassword, newPassword
            };
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/profile/change-password`, data, { 
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            return response.data;
        }
    }
}