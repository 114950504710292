import React from "react";
import { connect } from "react-redux";

class Thumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false });
        this.props.changeTh(reader.result);
      };
      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading } = this.state;
    const { th: thumb } = this.props;
    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <>
        <img
          src={thumb}
          alt={file.name}
          className="img-thumbnail mt-2"
          height={100}
          width={100}
          style={{ marginTop: 5 }}
        />
      </>
    );
  }
}

const mapState = (state) => ({
  count: state.count,
});

export default connect(mapState)(Thumb);
