import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import logo from "../../../src/assets/img/LogoOverlap.png";

const Loading = () => {
  return (
    <div
      style={{ height: "133vh" }}
      className={
        "bg-white w-full  overscroll-none absolute  z-40 backdrop-opacity-10 backdrop-invert bg-white/50"
      }
    >
      <Box
        sx={{
          position: "fixed",
          zIndex: 50,
          top: "35%",
          bottom: "50%",
          left: "40%",
          right: "40%",
        }}
      >
        <img style={{ marginBottom: 5, width: 400 }} src={logo} alt={"img"} />
        <div className={"flex justify-center"}>
          <CircularProgress />
        </div>
      </Box>
    </div>
  );
};

export default Loading;
