import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import translate from "../../i18n/messages/translate";
import img from "../../assets/img/preventivo.png";
import AddIcon from "@mui/icons-material/Add";
import { connect, useDispatch } from "react-redux";
import { importBaseActions } from "../../store/reducers/base";
import { useNavigate } from "react-router-dom";

const FirstPreventivo = () => {
  const dispatch = useDispatch();
  const baseActions = importBaseActions();

  //const history = useHistory();
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        height: "80%",
        display: "flex",
        // flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
      spacing={1}
    >
      <Grid
        item
        spacing={3}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant={"h4"}
          fontWeight={700}
          sx={{ mb: 4, textAlign: "center" }}
        >
          {translate("Crea_il_tuo_primo_preventivo")}
        </Typography>
        <img src={img} alt={"img"} />
        <Button
          onClick={() => {
            dispatch(baseActions.clearStorage());
            navigate("/nuova-configurazione");
          }}
          startIcon={<AddIcon />}
          color={"primary"}
          sx={{ color: "white", fontWeight: "bold", mt: 4 }}
          variant="contained"
          size="large"
        >
          {translate("NUOVO_PREVENTIVO")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FirstPreventivo;
